import { FETCH_EXHIBITIONS_LIST } from "../../../../../graphql/query/Exhibition";
import { exhibitionFilterStore } from "../../../components/useExhibitionFilters";

const SearchedExhibitions = props => {
  const tableProps = {
    dataKey: "exhibitionsV2",
    FETCH_QUERY: FETCH_EXHIBITIONS_LIST,
    limit: 50,
    variables: {
      ...exhibitionFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedExhibitions;
