import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CREATE_ARTIST as createArtistMutation } from "../../../../graphql/mutation/Artist";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, getInput } from "../../../../components/ui";

const NewArtist = props => {
  const { closeModal } = props;
  const navigate = useNavigate();
  const [createArtist, { loading }] = useMutation(createArtistMutation(), {
    refetchQueries: ["FetchArtists"],
  });

  const formik = useFormik({
    initialValues: {
      contactId: "",
    },
    validationSchema: Yup.object({
      contactId: Yup.number().required("Please select a contact"),
    }),
    onSubmit: async values => {
      createArtist({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createArtist: { success, artist } = {} },
        } = resp || {};
        if (success) {
          navigate(`/artist/${artist?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const inputs = [
    {
      label: "Contact",
      name: "contactId",
      type: "contactSearch",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new artist</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label="Create"
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50">
        {inputs
          ?.filter(item => !!item)
          .map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
      </div>
    </div>
  );
};

export default NewArtist;
