// import { useEffect, useState } from "react";
import { FETCH_CONTACTS_LIST as fetchContactsList } from "../../../../../graphql/query/Contact";
import { contactFilterStore } from "../../../components/useContactFilters";

const SearchedContacts = props => {
  const { salesUserId } = props || {};
  const tableProps = {
    dataKey: "contactsV2",
    FETCH_QUERY: fetchContactsList({}),
    variables: {
      ...contactFilterStore.get(),
      ...(salesUserId ? { salesUserId } : {}),
    },
  };

  return tableProps;
};

export default SearchedContacts;
