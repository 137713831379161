import { Route, Routes as RouterRoutes } from "react-router-dom";
import AuthRoute from "../../components/AuthRoute";
import routes from "./routes";
import { useFormik } from "formik";
import PrimaryNav from "./components/PrimaryNav";

const Body = props => (
  <RouterRoutes>
    {routes?.map((item, index) => {
      return (
        <Route
          key={index}
          path={item?.path}
          element={
            <AuthRoute permissions={item.permissions}>
              <item.element {...props} />
            </AuthRoute>
          }
        />
      );
    })}
  </RouterRoutes>
);

const Exhibition = props => {
  const formik = useFormik({
    initialValues: {},
  });
  return (
    <>
      <PrimaryNav formik={formik} />
      <Body {...props} formik={formik} />
    </>
  );
};

export default Exhibition;
