import { gql } from "@apollo/client";
import { ExhibitionWork as exhibitionWork } from "../fragments";

const FETCH_EXHIBITION_WORKS = gql`
	query FetchExhibitionWorks($input: ExhibitionWorksInput!) {
		exhibitionWorks(input: $input) {
			count
			edges {
				${exhibitionWork}
			}
		}
	}
`;

const FETCH_EXHIBITION_WORKS_V2 = props => gql`
  query FetchExhibitionWorksV2($input: AdvExhibitionWorkFilterInput!) {
    exhibitionWorks(input: $input) {
      count
      edges {
        ${exhibitionWork}
      }
      sqlQuery
    }
  }
`;

export { FETCH_EXHIBITION_WORKS, FETCH_EXHIBITION_WORKS_V2 };
