import { useNavigate } from "react-router-dom";
import {
  exhibitionFilterStore,
  useExhibitionFilters,
} from "../useExhibitionFilters";
import { useLazyQuery } from "@apollo/client";
import { FETCH_EXHIBITIONS_LIST as fetchExhibitionsListQuery } from "../../../../graphql/query/Exhibition";
import { Button } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";

const ExhibitionSearch = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const { formik } = useExhibitionFilters();
  const input = exhibitionFilterStore.get();
  const [fetchExhibitionsV2, { loading }] = useLazyQuery(
    fetchExhibitionsListQuery,
  );

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "Feburary" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const inputs = {
    className: "grid grid-cols-3 gap-4 mt-5 items-top",
    inputs: [
      {
        label: "Exhibition Title",
        name: "title",
        type: "text",
      },
      {
        label: "Contact",
        name: "contactId",
        type: "contactSearch",
      },
      {
        hasOperator: true,
        isMulti: true,
        label: "Type",
        name: "type",
        type: "multi-select",
        options: [
          { label: "Solo", value: "Solo" },
          { label: "Group", value: "Group" },
          { label: "Artfair", value: "Artfair" },
          { label: "External", value: "External" },
          { label: "Cancelled", value: "Cancelled" },
        ],
      },
      {
        label: "Start Day",
        name: "startDay",
        type: "number",
        min: 1,
        max: 31,
        allowEmpty: true,
      },
      {
        label: "Start Month",
        name: "startMonth",
        type: "multi-select",
        options: monthOptions,
      },
      {
        label: "Start Year",
        name: "startYear",
        type: "number",
        min: 1900,
        max: 2099,
        allowEmpty: true,
      },
      {
        label: "End Day",
        name: "endDay",
        type: "number",
        min: 1,
        max: 31,
        allowEmpty: true,
      },
      {
        label: "End Month",
        name: "endMonth",
        type: "multi-select",
        options: monthOptions,
      },
      {
        label: "End Year",
        name: "endYear",
        type: "number",
        min: 1900,
        max: 2099,
        allowEmpty: true,
      },
      {
        label: "Sage Code",
        name: "sageCode",
        type: "text",
      },
      {
        label: "ID",
        name: "id",
        type: "text",
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: () =>
      fetchExhibitionsV2({ variables: { input } }).then(() => {
        navigate("/exhibition?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Exhibition Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      <div>
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

export default ExhibitionSearch;
