import Instance from "./Instance";

const ExhibitionWork = `
  artworkCat
  exhibited
  exhibitionId
  id
  instance {
    ${Instance({
      description: {},
      edition: { artwork: { artists: {}, imagesSummary: { imgT: {} } } },
      locationHistory: {},
      location: {},
      status: {},
      value: {
        currency: {},
      },
    })}
  }
  instanceId
  notes
  removed
  technicalNotes
  updatedAt
  user
`;

export default ExhibitionWork;
