import { gql } from "@apollo/client";
import { Exhibition } from "../fragments";

const CREATE_EXHIBITION = props => gql`
	mutation CreateExhibition($input: CreateExhibitionInput!) {
		createExhibition(input: $input) {
			success
			error
			exhibition {
				${Exhibition(props)}
			}
		}
	}
`;

const DUPLICATE_EXHIBITION = props => gql`
	mutation DuplicateExhibition($input: DuplicateExhibitionInput!) {
		duplicateExhibition(input: $input) {
			success
			error
			exhibition {
				${Exhibition(props)}
			}
		}
	}
`;

const UPDATE_EXHIBITION = props => gql`
	mutation UpdateExhibition($input: UpdateExhibitionInput!) {
		updateExhibition(input: $input) {
			success
			error
			exhibition {
				${Exhibition(props)}
			}
		}
	}
`;

const EXPORT_ADHOC_REPORT_EXHIBITIONS = gql`
  mutation ExportAdhocReportInstances($input: ExportExhibitionsInput!) {
    exportAdhocReportExhibitions(input: $input) {
      error
      success
      url
    }
  }
`;

export {
  CREATE_EXHIBITION,
  DUPLICATE_EXHIBITION,
  UPDATE_EXHIBITION,
  EXPORT_ADHOC_REPORT_EXHIBITIONS,
};
