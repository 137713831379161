import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CREATE_EXHIBITION } from "../../../../graphql/mutation/Exhibition";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, getInput } from "../../../../components/ui";

const NewExhibition = props => {
  const { closeModal } = props;
  const navigate = useNavigate();
  const [createExhibition, { loading }] = useMutation(CREATE_EXHIBITION(), {
    refetchQueries: ["FetchExhibitionsV2"],
  });
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      contactId: Yup.number().required("Please select a contact"),
      title: Yup.string().required("Please enter an exhibition title"),
      startDay: Yup.number()
        .nullable()
        .min(1, "Start Day must be at least 1")
        .max(31, "Start Day must be 31 at most"),
      startYear: Yup.number()
        .nullable()
        .min(1900, "Start Year must be at least 1900")
        .max(2099, "Start Year must be at least 2099"),
      endDay: Yup.number()
        .nullable()
        .min(1, "Start Day must be at least 1")
        .max(31, "Start Day must be 31 at most"),
      endYear: Yup.number()
        .nullable()
        .min(1900, "End Year must be at least 1900")
        .max(2099, "End Year must be at least 2099"),
      sageCode: Yup.string().required("Please enter a Sage Code"),
    }),
    onSubmit: async values => {
      createExhibition({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createExhibition: { success, exhibition } = {} },
        } = resp || {};
        if (success) {
          navigate(`/exhibition/${exhibition?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "Feburary" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const inputs = [
    {
      label: "Title",
      name: "title",
      type: "text",
      classes: "col-span-2",
    },
    {
      label: "Contact",
      name: "contactId",
      type: "contactSearch",
      classes: "col-start-1",
    },
    {
      label: "Type",
      name: "type",
      type: "multi-select",
      options: [
        { label: "Solo", value: "Solo" },
        { label: "Group", value: "Group" },
        { label: "Artfair", value: "Artfair" },
        { label: "External", value: "External" },
        { label: "Cancelled", value: "Cancelled" },
      ],
    },
    {
      label: "Curator",
      name: "curator",
      type: "text",
    },
    {
      label: "Start Day",
      name: "startDay",
      type: "number",
      allowEmpty: true,
      min: 1,
      max: 31,
    },
    {
      label: "Start Month",
      name: "startMonth",
      type: "multi-select",
      options: monthOptions,
    },
    {
      label: "Start Year",
      name: "startYear",
      type: "number",
      allowEmpty: true,
      min: 1900,
      max: 2099,
    },
    {
      label: "End Day",
      name: "endDay",
      type: "number",
      allowEmpty: true,
      min: 1,
      max: 31,
    },
    {
      label: "End Month",
      name: "endMonth",
      type: "multi-select",
      options: monthOptions,
    },
    {
      label: "End Year",
      name: "endYear",
      type: "number",
      allowEmpty: true,
      min: 1900,
      max: 2099,
    },
    {
      label: "Sage Code",
      name: "sageCode",
      type: "text",
    },
    {
      label: "Notes",
      name: "notes",
      type: "textarea",
      rows: 2,
      classes: "col-span-2",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new exhibition</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label="Create"
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {inputs
          ?.filter(item => !!item)
          .map((item, index) => {
            const { classes } = item;
            const inputProps = {
              ...item,
              formik,
            };
            return (
              <div key={index} className={classes}>
                {getInput(inputProps)}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NewExhibition;
