import { FETCH_ARTWORKS_LIST as fetchArtworksList } from "../../../../../graphql/query/Artwork";
import { artworkFilterStore } from "../../../components/useArtworkFilters";

const SearchedArtworks = props => {
  const tableProps = {
    dataKey: "artworksV2",
    FETCH_QUERY: fetchArtworksList({}),
    variables: {
      ...artworkFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedArtworks;
