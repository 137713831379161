import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, Button, MenuButton, Modal } from "../../../components/ui";
import ExportActiveReport from "../../../components/Modals/ExportActiveReport";
import { FETCH_ARTWORK as fetchArtwork } from "../../../graphql/query/Artwork";

// import useUser from "../../../hooks/useUser"
import routes from "../pages/Wizard/routes";

import NewArtwork from "./NewArtwork";
import ArtworkSearch from "./ArtworkSearch";
import useUser from "../../../hooks/useUser";
import DuplicateArtwork from "./DuplicateArtwork";
import exportModule from "./Actions/ExportModule";
import {
  DELETE_ARTWORK,
  BATCH_UPDATE_ARTWORK as batchUpdateArtworkMutation,
} from "../../../graphql/mutation/Artwork";
import AdhocReportBody from "./Actions/AdhocReportBody";
import AddOffersBody from "./Actions/AddOffersBody";
import BatchEditBody from "./Actions/BatchEditBody";
import { artworkFilterStore } from "./useArtworkFilters";
import { useState } from "react";

const PrimaryNav = props => {
  const { formik } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location?.pathname.split("/");
  const search = artworkFilterStore.get();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { hasPermission } = useUser();
  // get id from url
  const id = pathnames?.length > 2 ? pathnames[2] : null;
  const artworkObj = {
    artists: { contact: {} },
    artistDescription: {},
    editions: { artwork: {}, instances: {}, availableNumbers: {}, type: {} },
    fullDescription: {},
    pressDescription: {},
    images: { imgT: {}, imgX: {}, photoMaterial: {} },
    imagesSummary: { imgT: {}, imgX: {}, photoMaterial: {} },
  };
  // fetch artwork details if id is available
  const { data: { artwork } = {} } = useQuery(fetchArtwork(artworkObj), {
    skip: !id,
    variables: { id: +id },
  });

  // find current submodule route
  const path = pathnames[pathnames?.length - 1];
  const route = routes?.find(item => item?.href?.includes(path));
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status") || "all";
  const selected = Object.keys(formik?.values?.selected?.ids || {});
  const listId = +(queryParams.get("listId") || status === "selectListWorking"
    ? queryParams.get("workingListId")
    : null);
  const ids = selected?.map(key => {
    const obj = formik?.values?.selected?.ids?.[key];
    return obj?.item?.id || obj?.id;
  });
  const select = formik?.values?.selected?.select;
  const disabled = ids?.length === 0;

  const [
    deleteArtwork,
    {
      data: { deleteArtwork: { error: deleteArtworkError } = {} } = {},
      loading: deleteArtworkLoading,
      reset,
    },
  ] = useMutation(DELETE_ARTWORK);
  const [
    batchUpdateArtwork,
    {
      data: { batchUpdateArtwork: { error: tbcArtworksError } = {} } = {},
      loading: tbcArtworksLoading,
    },
  ] = useMutation(batchUpdateArtworkMutation());

  const statusMap = {
    all: "All Artworks",
    searched: "Searched Artworks",
    selectListWorking: "Select List (Working)",
  };
  const label = statusMap[status] || "All Artworks";
  // prepare steps for breadcrumbs
  const steps = [
    { label, onClick: () => navigate(`/artworks?status=${status}`) },
    ...(artwork
      ? [
          {
            label: artwork?.title,
            onClick: () => navigate(`${artwork?.id}/details?status=${status}`),
          },
          {
            label: route?.name,
            onClick: () => navigate(`${artwork?.id}/${route?.href}`),
          },
        ]
      : []),
  ];

  const breadcrumbProps = {
    currentStepIndex: steps?.length - 1,
    onChange: index => steps[index]?.onClick(),
    steps: steps?.map(item => item?.label),
  };

  const modalProps = {
    body: ArtworkSearch,
    closeOnBackdrop: true,
    scale: "fullscreen",
    isSearchOpen,
    onClose: () => setIsSearchOpen(false),
  };

  const defaultActionProps = {
    closeOnBackdrop: false,
    hideCloseButton: true,
    ids,
    listId,
    onClose: () => {
      formik?.resetForm();
    },
    scale: "md",
    search,
    select,
  };

  const copyDescriptionModalProps = {
    title: "Copy Press Description",
    scale: "sm",
    description: "Are you sure you wish to copy press description of artwork?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      // eslint-disable-next-line
      const [fetchArtworkDescription, { loading }] = useLazyQuery(
        fetchArtwork({ pressDescription: true }),
        { fetchPolicy: "network-only" },
      );

      const handleCopyDescription = async () => {
        try {
          const { data } = await fetchArtworkDescription({
            variables: { id: +id },
          });
          const description = data?.artwork?.pressDescription;
          navigator.clipboard.writeText(description);
          closeModal();
        } catch (err) {
          console.log("err: ", err);
        }
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            disabled={loading}
            label={loading ? "Fetching description" : "Copy description"}
            onClick={handleCopyDescription}
          />
        </div>
      );
    },
  };

  const copyFullDescriptionModalProps = {
    title: "Copy Description",
    scale: "sm",
    description: "Are you sure you wish to copy description of artwork?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      // eslint-disable-next-line
      const [fetchArtworkDescription, { loading }] = useLazyQuery(
        fetchArtwork({ fullDescription: true }),
        { fetchPolicy: "network-only" },
      );

      const handleCopyDescription = async () => {
        try {
          const { data } = await fetchArtworkDescription({
            variables: { id: +id },
          });
          const description = data?.artwork?.fullDescription;
          navigator.clipboard.writeText(description);
          closeModal();
        } catch (err) {
          console.log("err: ", err);
        }
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            disabled={loading}
            label={loading ? "Fetching description" : "Copy description"}
            onClick={handleCopyDescription}
          />
        </div>
      );
    },
  };

  const copyArtistDescriptionModalProps = {
    title: "Copy Artist Description",
    scale: "sm",
    description: "Are you sure you wish to copy artist description of artwork?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      // eslint-disable-next-line
      const [fetchArtworkDescription, { loading }] = useLazyQuery(
        fetchArtwork({ artistDescription: true }),
        { fetchPolicy: "network-only" },
      );

      const handleCopyDescription = async () => {
        try {
          const { data } = await fetchArtworkDescription({
            variables: { id: +id },
          });
          const description = data?.artwork?.artistDescription;
          navigator.clipboard.writeText(description);
          closeModal();
        } catch (err) {
          console.log("err: ", err);
        }
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            disabled={loading}
            label={loading ? "Fetching description" : "Copy description"}
            onClick={handleCopyDescription}
          />
        </div>
      );
    },
  };

  const copyArchiveDescriptionModalProps = {
    title: "Copy Archive Description",
    scale: "sm",
    description:
      "Are you sure you wish to copy archive description of artwork?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      // eslint-disable-next-line
      const [fetchArtworkDescription, { loading }] = useLazyQuery(
        fetchArtwork({ archiveDescription: true }),
        { fetchPolicy: "network-only" },
      );

      const handleCopyDescription = async () => {
        try {
          const { data } = await fetchArtworkDescription({
            variables: { id: +id },
          });
          const description = data?.artwork?.archiveDescription;
          navigator.clipboard.writeText(description);
          closeModal();
        } catch (err) {
          console.log("err: ", err);
        }
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            disabled={loading}
            label={loading ? "Fetching description" : "Copy description"}
            onClick={handleCopyDescription}
          />
        </div>
      );
    },
  };

  const deleteModalProps = {
    title: deleteArtworkError ? "Error" : "Delete Artwork",
    scale: "sm",
    description: deleteArtworkError
      ? deleteArtworkError
      : "Are you sure you wish to delete this artwork? This cannot be undone.",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteArtwork({
          variables: {
            input: { id: artwork?.id },
          },
          update: (cache, { data }) => {
            const { deleteArtwork: { success } = {} } = data || {};
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "Artwork",
                  id,
                }),
              });
              navigate("/artworks");
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={deleteArtworkError ? "Close" : "Cancel"}
            action={deleteArtworkError ? "primary" : "default"}
            onClick={() => {
              reset();
              closeModal?.();
            }}
          />
          {!deleteArtworkError && (
            <Button
              label={deleteArtworkLoading ? "Deleting" : "Delete"}
              disabled={deleteArtworkLoading}
              onClick={handleDelete}
            />
          )}
        </div>
      );
    },
  };

  const tbcModalProps = {
    title: tbcArtworksError ? "Error" : "TBC Artwork",
    scale: "sm",
    description: tbcArtworksError
      ? tbcArtworksError
      : "Are you sure you wish to add TBC to selected artworks?",
    closeOnBackdrop: true,
    body: ({ closeModal }) => {
      const handleClick = () => {
        batchUpdateArtwork({
          variables: {
            input: {
              ids: +id ? [+id] : ids,
              dimensions: "Dimensions to be confirmed",
              medium: "Medium to be confirmed",
              title: "[Title to be confirmed]",
            },
          },
          update: (cache, { data }) => {
            const { batchUpdateArtwork: { success } = {} } = data || {};
            if (success) {
              closeModal();
              formik?.resetForm?.();
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={tbcArtworksError ? "Close" : "Cancel"}
            action={tbcArtworksError ? "primary" : "default"}
            onClick={() => {
              reset();
              closeModal?.();
            }}
          />
          {!tbcArtworksError && (
            <Button
              label={tbcArtworksLoading ? "Processing" : "Confirm"}
              disabled={tbcArtworksLoading}
              onClick={handleClick}
            />
          )}
        </div>
      );
    },
  };

  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isExportReportOpen, setIsExportReportOpen] = useState(false);
  const [isBatchEditOpen, setIsBatchEditOpen] = useState(false);

  const actionMenuProps = {
    label: "Actions",
    options: [
      {
        label: "New Artwork",
        modalProps: {
          body: NewArtwork,
          closeOnBackdrop: false,
          artwork,
          hideCloseButton: true,
          scale: "md",
          isCreateOpen,
          onOpen: () => {
            setIsCreateOpen(true);
          },
          onClose: () => {
            setIsCreateOpen(false);
          },
        },
      },
      {
        label: "Copy Description",
        modalProps: copyFullDescriptionModalProps,
      },
      {
        label: "Copy Archive Description",
        modalProps: copyArchiveDescriptionModalProps,
      },
      {
        label: "Copy Artist Description",
        modalProps: copyArtistDescriptionModalProps,
      },
      {
        label: "Copy Press Description",
        modalProps: copyDescriptionModalProps,
      },
      {
        disabled,
        label: "Add to Offers Select List",
        modalProps: {
          body: AddOffersBody,
          ...defaultActionProps,
        },
      },
      {
        disabled,
        label: "Create an Adhoc Report",
        modalProps: {
          body: AdhocReportBody,
          ...defaultActionProps,
          exportModule,
        },
      },
      {
        disabled: !artwork?.id && disabled,
        label: "Export Artwork Report",
        modalProps: {
          ...defaultActionProps,
          body: ExportActiveReport,
          obj: artworkObj,
          type: "Artwork",
          ids: artwork?.id ? [artwork?.id] : ids,
          isExportReportOpen,
          onOpen: () => {
            setIsExportReportOpen(true);
          },
          onClose: () => {
            setIsExportReportOpen(false);
          },
        },
      },
      {
        disabled,
        label: "Batch Edit",
        modalProps: {
          body: BatchEditBody,
          isBatchEditOpen,
          onOpen: () => {
            setIsBatchEditOpen(true);
          },
          onClose: () => {
            setIsBatchEditOpen(false);
          },
          ...defaultActionProps,
        },
      },
      {
        disabled: !ids?.length && !id,
        label: "TBC",
        modalProps: tbcModalProps,
      },
      ...(id
        ? [
            {
              disabled: !hasPermission("DELETE_ARTWORK"),
              label: "Delete artwork",
              modalProps: deleteModalProps,
            },
            {
              label: "Duplicate Artwork",
              modalProps: {
                body: DuplicateArtwork,
                closeOnBackdrop: false,
                artwork,
                hideCloseButton: true,
                scale: "md",
              },
            },
          ]
        : []),
    ],
  };

  return (
    <div className="flex items-center justify-between border-b px-8 py-5">
      <Breadcrumb {...breadcrumbProps} />
      <div className="flex justify-end gap-4">
        <Modal {...modalProps}>
          <Button
            label="Artwork Search"
            action="black"
            onClick={() => setIsSearchOpen(true)}
          />
        </Modal>
        <MenuButton {...actionMenuProps} />
      </div>
    </div>
  );
};

export default PrimaryNav;
