import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button, Spinner } from "../../../../components/ui";

import { FETCH_ARTISTS as fetchArtists } from "../../../../graphql/query/Artist";

import Query from "./components/Query";
import Tabs from "./components/Tabs";
import {
  FETCH_WAREHOUSE_ITEMS_LIST as fetchWarehouseItemsList,
  FETCH_WAREHOUSE_MGROUP,
} from "../../../../graphql/query/WarehouseItem";
import {
  useWarehouseFilters,
  warehouseFilterStore,
} from "../useWarehouseFilter";
import { FETCH_WAREHOUSE_CATEGORIES } from "../../../../graphql/query/WarehouseCategory";
import Instance from "./components/Instance";
import Location from "./components/Location";
import Movements from "./components/Movements";
import { useEffect } from "react";

const WarehouseSearch = props => {
  const { closeModal, isSearchOpen = false } = props || {};
  const [fetchWarehouseItemsV2, { loading, data: { warehouseItemsV2 } = {} }] =
    useLazyQuery(fetchWarehouseItemsList({}));

  const [
    fetchArtistsLazily,
    { loading: artistsLoading, data: { artists = [] } = {} },
  ] = useLazyQuery(fetchArtists());

  const [
    fetchWarehouseCategoriesLazily,
    {
      loading: warehouseCategoriesLoading,
      data: { warehouseCategories = [] } = {},
    },
  ] = useLazyQuery(FETCH_WAREHOUSE_CATEGORIES);

  const [
    fetchWarehouseMGroupLazily,
    { loading: warehouseMGroupLoading, data: { warehouseMGroup = {} } = {} },
  ] = useLazyQuery(FETCH_WAREHOUSE_MGROUP);

  useEffect(() => {
    if (isSearchOpen) {
      fetchArtistsLazily();
      fetchWarehouseCategoriesLazily();
      fetchWarehouseMGroupLazily();
    }
  }, [isSearchOpen]);

  const navigate = useNavigate();
  const { formik } = useWarehouseFilters();
  const input = warehouseFilterStore.get();

  useEffect(() => {
    if (formik?.values?._warehouseMove?.ti) {
      formik.setFieldValue("sort1", {
        operator: "DESC",
        value: "updatedAt",
      });
    }
  }, [formik?.values?._warehouseMove?.ti]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          { label: "Item #", name: "id", type: "text" },
          {
            hasOperator: true,
            isMulti: true,
            label: "Category",
            name: "categoryId",
            type: "multi-select",
            options: warehouseCategories?.map(item => ({
              label: item.description,
              value: item.id,
            })),
          },
          {
            label: "Artist",
            name: "instanceArtistId",
            options: artists?.map(item => ({
              label: item.name,
              value: item.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "External", name: "externalRef", type: "text" },
          {
            label: "Group",
            name: "mgroup",
            type: "multi-select",
            options: warehouseMGroup?.mgroups?.map(item => ({
              label: item,
              value: item,
            })),
          },
          { label: "Container", name: "container", type: "text" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Description", name: "description", type: "textarea" },
          { label: "Notes", name: "notes", type: "textarea" },
        ],
      },
      {
        className: "grid-cols-1 gap-4 pt-4",
        inputs: [
          {
            designType: "threeStateCheckbox",
            label: "Destroyed",
            name: "destroyed",
            type: "toggle",
          },
          {
            designType: "threeStateCheckbox",
            label: "Bonded",
            name: "warehouseMoveTI",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchWarehouseItemsV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/warehouse?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    warehouseItemsV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Instance",
        value: "instance",
        component: <Instance {...tabComponentProps} />,
      },
      {
        label: "Location",
        value: "location",
        component: <Location {...tabComponentProps} />,
      },
      {
        label: "Movements",
        value: "movements",
        component: <Movements {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Warehouse Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      {artistsLoading ||
      warehouseCategoriesLoading ||
      warehouseMGroupLoading ? (
        <div className="flex h-[70dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <GridInputs {...inputProps} />
          <Tabs {...tabsProps} />
        </div>
      )}
    </div>
  );
};

export default WarehouseSearch;
