import { useMutation } from "@apollo/client";
import { DELETE_EXHIBITION_WORK } from "../../../../../../../graphql/mutation/ExhibitionWork";
import { Button, IconButton, Modal } from "../../../../../../../components/ui";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useUser from "../../../../../../../hooks/useUser";

const Actions = props => {
  const { id } = props || {};
  const [deleteExhibitionWork, { loading, error }] = useMutation(
    DELETE_EXHIBITION_WORK,
    {
      refetchQueries: ["FetchExhibitionWorks"],
    },
  );
  const { hasPermission } = useUser();

  const modalProps = {
    title: error ? "Error deleting exhibition artwork" : "Are you sure?",
    scale: "sm",
    description: error
      ? error?.message
      : `Would you like to delete this exhibition artwork?`,
    closeOnBackdrop: true,
    disabled: !hasPermission("DELETE_EXHIBITION_WORK"),
    body: ({ closeModal }) => {
      const handleDelete = () => {
        deleteExhibitionWork({
          variables: {
            input: { id },
          },
          update: (cache, { data }) => {
            const { deleteExhibitionWork: { success } = {} } = data;
            if (success) {
              closeModal();
              cache.evict({
                id: cache.identify({
                  __typename: "ExhibitionWork",
                  id,
                }),
              });
            }
          },
        });
      };

      return (
        <div className="mt-4 flex">
          <Button
            className="mr-3"
            label={"Cancel"}
            action={"default"}
            onClick={() => {
              closeModal?.();
            }}
          />
          <Button
            label={loading ? "Deleting" : "Delete"}
            disabled={loading}
            onClick={handleDelete}
          />
        </div>
      );
    },
  };

  if (typeof id == "number") {
    return (
      <div className="user-list-actions flex text-right text-sm font-medium">
        {id && (
          <div className="grid">
            <Modal {...modalProps}>
              <IconButton variant="clean" title="Delete Exhibition">
                {hasPermission("DELETE_EXHIBITION_WORK") && (
                  <XMarkIcon
                    className={`h-4 w-4 cursor-pointer text-gray-400`}
                  />
                )}
              </IconButton>
            </Modal>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Actions;
