// import { useEffect, useState } from "react";
import { FETCH_WAREHOUSE_ITEMS_LIST as fetchWarehouseItemsList } from "../../../../../graphql/query/WarehouseItem";
import { warehouseFilterStore } from "../../../components/useWarehouseFilter";

const SearchedWarehouses = props => {
  const tableProps = {
    dataKey: "warehouseItemsV2",
    FETCH_QUERY: fetchWarehouseItemsList({}),
    variables: {
      ...warehouseFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedWarehouses;
