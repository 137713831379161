import { gql } from "@apollo/client";
import { Consignment as consignment } from "../fragments";

const FETCH_CONSIGNMENT = props => gql`
  query FetchConsignment($id: Int!) {
    consignment(id: $id) {
      ${consignment(props)}
      isConsignIn
      isSecondary
    }
  }
`;

const FETCH_CONSIGNMENTS = props => gql`
  query FetchConsignments($input: ConsignmentFilterInput!) {
    consignments(input: $input) {
      count
      edges {
        ${consignment(props)}
      }
    }
  }
`;

const FETCH_CONSIGNMENTS_V2 = props => gql`
  query FetchConsignmentsV2($input: AdvConsignmentFilterInput!) {
    consignmentsV2(input: $input) {
      count
      sqlQuery
      edges {
        ${consignment(props)}
      }
    }
  }
`;

const FETCH_CONSIGNMENTS_LIST = gql`
  query FetchConsignmentsV2($input: AdvConsignmentFilterInput!) {
    consignmentsV2(input: $input) {
      count
      sqlQuery
      edges {
        id
        consignmentWorks {
          instance {
            name
          }
        }
        contact {
          company
          name
        }
        date
        noOfConsignmentWorks
        notes
        type {
          description
        }
      }
    }
  }
`;

export {
  FETCH_CONSIGNMENT,
  FETCH_CONSIGNMENTS,
  FETCH_CONSIGNMENTS_V2,
  FETCH_CONSIGNMENTS_LIST,
};
