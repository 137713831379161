import { gql } from "@apollo/client";
import { Artwork as artwork } from "../fragments";

const FETCH_ARTWORK = props => gql`
  query FetchArtwork($id: Int) {
    artwork(id: $id) {
      ${artwork(props)}
    }
  }
`;

const FETCH_ARTWORKS = props => gql`
  query FetchArtworks($input: ArtworkFilterInput!) {
    artworks(input: $input) {
      count
      edges {
        ${artwork(props)}
      }
    }
  }
`;

const FETCH_ARTWORKS_V2 = props => gql`
  query FetchArtworksV2($input: AdvArtworkFilterInput!) {
    artworksV2(input: $input) {
      count
      edges {
        ${artwork(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_ARTWORKS_LIST = props => gql`
  query FetchArtworksList($input: AdvArtworkFilterInput!) {
    artworksV2(input: $input) {
      count
      edges {
        id
        imagesSummary {
          imgT
        }
        artists {
          name
        }
        title
        description
        medium
        dimensions
      }
      sqlQuery
    }
  }
`;

export {
  FETCH_ARTWORK,
  FETCH_ARTWORKS,
  FETCH_ARTWORKS_V2,
  FETCH_ARTWORKS_LIST,
};
