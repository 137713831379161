import { artistFilterStore, useArtistFilters } from "../useArtistFilters";
import {
  FETCH_ARTISTS_V2 as fetchArtistsListQuery,
  FETCH_ARTISTS as fetchArtists,
} from "../../../../graphql/query/Artist";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";
import { useEffect } from "react";

const ArtistSearch = props => {
  const { closeModal, isSearchOpen = false } = props || {};
  const navigate = useNavigate();
  const { formik } = useArtistFilters();
  const input = artistFilterStore.get();

  const [
    fetchArtistsLazily,
    { loading: artistsLoading, data: { artists = [] } = {} },
  ] = useLazyQuery(fetchArtists());
  const [fetchArtistV2, { loading }] = useLazyQuery(fetchArtistsListQuery());

  useEffect(() => {
    if (isSearchOpen) {
      fetchArtistsLazily();
    }
  }, [isSearchOpen]);

  const inputs = {
    className: "grid grid-cols-2 gap-4 mt-5",
    inputs: [
      {
        labelClass: "mt-[13px]",
        isMulti: true,
        label: "Artist",
        name: "id",
        type: "multi-select",
        options: artists?.map(item => ({
          label: item?.name,
          value: item?.id,
        })),
      },
      {
        hasOperator: true,
        isMulti: true,
        label: "Represented",
        name: "represented",
        options: [
          { label: "Represented", value: 0 },
          { label: "External", value: 1 },
          { label: "Secondary Market", value: 2 },
          { label: "Not Represented", value: 3 },
        ],
        type: "multi-select",
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: () =>
      fetchArtistV2({ variables: { input } }).then(() => {
        navigate("/artist?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Artist Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      {artistsLoading ? (
        <div className="flex h-[70dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div>
          <GridInputs {...inputProps} />
        </div>
      )}
    </div>
  );
};

export default ArtistSearch;
