import { FETCH_INSTANCES_LIST as fetchInstancesList } from "../../../../../graphql/query/Instance";
import { instanceFilterStore } from "../../../components/useInstanceFilters";

const SearchedInstances = props => {
  const tableProps = {
    dataKey: "instancesV2",
    FETCH_QUERY: fetchInstancesList,
    limit: 50,
    variables: {
      ...instanceFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedInstances;
