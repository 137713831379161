import contactFrag from "./Contact";
import ExhibitionWork from "./ExhibitionWork";

const Exhibition = ({ contact = false, exhibitionWorks = true } = {}) => `
  id
  artFair
  ${
    contact
      ? `contact {
      ${contactFrag(contact)}
    }`
      : ""
  }
  contactId
  curator
  endDate
  endDay
  endMonth
  endYear
  ${
    exhibitionWorks
      ? `exhibitionWorks {
      ${ExhibitionWork}
    }`
      : ""
  }
  fullDate
  notes
  sageCode
  startDate
  startDay
  startMonth
  startYear
  title
  type
  updatedAt
`;

export default Exhibition;
