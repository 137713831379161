import { gql } from "@apollo/client";
import ExhibitionDocument from "../fragments/ExhibitionDocument";

const FETCH_EXHIBITION_DOCUMENTS = gql`
	query FetchExhibitionDocuments($input: ExhibitionDocumentFilterInput!) {
		exhibitionDocuments(input: $input) {
			count
			edges {
				${ExhibitionDocument}
			}
		}
	}
`;

export { FETCH_EXHIBITION_DOCUMENTS };
