import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button, Spinner } from "../../../../components/ui";

import {
  consignmentFilterStore,
  useConsignmentFilters,
} from "../useConsignmentFilters";
import Tabs from "./components/Tabs";
import Artworks from "./components/Artworks";
import { FETCH_CURRENCIES } from "../../../../graphql/query/Currency";
import { FETCH_CONSIGNMENTS_LIST as fetchConsignmentsListQuery } from "../../../../graphql/query/Consignment";
import { FETCH_CONSIGNMENTS_TYPES } from "../../../../graphql/query/ConsignmentType";
import { FETCH_CONTACT_TYPES } from "../../../../graphql/query/ContactType";

import Schedule from "./components/Schedule";
import Registrar from "./components/Registrar";
import Sort from "./components/Sort";
import Query from "./components/Query";

const ConsignmentSearch = props => {
  const { closeModal, isSearchOpen = false } = props || {};
  const [fetchConsignmentsV2, { loading, data: { consignmentsV2 } = {} }] =
    useLazyQuery(fetchConsignmentsListQuery);
  const [
    fetchCurrenciesLazily,
    { loading: currenciesLoading, data: { currencies = [] } = {} },
  ] = useLazyQuery(FETCH_CURRENCIES);
  const [
    fetchConsignmentTypesLazily,
    { loading: consignmentTypesLoading, data: { consignmentTypes = [] } = {} },
  ] = useLazyQuery(FETCH_CONSIGNMENTS_TYPES);
  const [
    fetchContactTypesLazily,
    { loading: contactTypesLoading, data: { contactTypes = [] } = {} },
  ] = useLazyQuery(FETCH_CONTACT_TYPES);

  const navigate = useNavigate();
  const { formik } = useConsignmentFilters();
  const input = consignmentFilterStore.get();

  useEffect(() => {
    if (isSearchOpen) {
      fetchConsignmentTypesLazily();
      fetchContactTypesLazily();
      fetchCurrenciesLazily();
    }
  }, [isSearchOpen]);

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            options: consignmentTypes?.map(consignmentType => ({
              label: consignmentType?.description,
              value: consignmentType?.id,
            })),
          },
          { label: "Consignment #", name: "id", type: "number" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Consignee", name: "contactId", type: "contactSearch" },
          {
            label: "Consigner",
            name: "consignerId",
            type: "contactSearch",
          },
          {
            hasOperator: true,
            isMulti: true,
            label: "Consigner Type",
            name: "_contact.typeId",
            options: contactTypes?.map(contactType => ({
              label: contactType?.description,
              value: contactType?.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Date - From",
            name: "date.value.from",
            type: "date",
          },
          {
            label: "Date - To",
            name: "date.value.to",
            type: "date",
          },
          {
            className: "mt-5",
            label: "Due - From",
            name: "due.value.from",
            type: "date",
          },
          { label: "Due - To", name: "due.value.to", type: "date" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Currency",
            name: "currencyId",
            type: "multi-select",
            options: currencies?.map(currency => ({
              label: `${currency?.description} (${currency?.code})`,
              value: currency?.id,
            })),
          },
          { label: "Terms", name: "terms", type: "textarea" },
          { label: "Notes", name: "notes", type: "textarea" },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchConsignmentsV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/consignment?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    consignmentsV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Artworks",
        value: "artworks",
        component: <Artworks {...tabComponentProps} />,
      },
      {
        label: "Schedule",
        value: "shedule",
        component: <Schedule {...tabComponentProps} />,
      },
      {
        label: "Registrar",
        value: "registrar",
        component: <Registrar {...tabComponentProps} />,
      },
      {
        label: "Sort",
        value: "sort",
        component: <Sort {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Consignment Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      {consignmentTypesLoading || currenciesLoading || contactTypesLoading ? (
        <div className="flex h-[70dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <GridInputs {...inputProps} />
          <Tabs {...tabsProps} />
        </div>
      )}
    </div>
  );
};

export default ConsignmentSearch;
