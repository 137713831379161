const ExhibitionDocument = `
  id
  fileName
  fileType
  exhibitionId
  updatedAt
  url
  viewUrl
`;

export default ExhibitionDocument;
