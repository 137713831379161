import { FETCH_INVOICES_LIST as fetchInvoicesList } from "../../../../../graphql/query/Invoice";
import { invoiceFilterStore } from "../../../components/useInvoiceFilters";

const SearchedInvoices = () => {
  const tableProps = {
    dataKey: "invoicesV2",
    FETCH_QUERY: fetchInvoicesList({}),
    variables: {
      ...invoiceFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedInvoices;
