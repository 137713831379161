import { gql } from "@apollo/client";
import { Artist as artist } from "../fragments";

const FETCH_ARTIST = props => gql`
  query FetchArtist($id: Int) {
    artist(id: $id) {
      ${artist(props)}
    }
  }
`;

const FETCH_ARTISTS = props => gql`
  query FetchArtists {
    artists {
      ${artist(props)}
    }
  }
`;

const FETCH_ARTISTS_SEARCH = props => gql`
  query FetchArtistsSearch {
    artists {
      id
      name
    }
  }
`;

const FETCH_ARTISTS_V2 = props => gql`
  query FetchArtistsV2($input: AdvArtistFilterInput!) {
    artistsV2(input: $input) {
      count
      edges {
        ${artist(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_ARTISTS_LIST = props => gql`
  query FetchArtistsList($input: AdvArtistFilterInput!) {
    artistsV2(input: $input) {
      count
      edges {
        id
        name
        bornCountry {
          name
        }
        represented
        confidential
      }
      sqlQuery
    }
  }
`;

export {
  FETCH_ARTIST,
  FETCH_ARTISTS,
  FETCH_ARTISTS_V2,
  FETCH_ARTISTS_LIST,
  FETCH_ARTISTS_SEARCH,
};
