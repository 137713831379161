// import contact from './Contact'
import image from "./Image";
import instance from "./Instance";
import job from "./Job";
import company from "./Company";
import exhibitionWork from "./ExhibitionWork";

const ListItems = ({ item = false } = {}) => `
  id
  ${
    item
      ? `item {
      __typename
      ... on Artist {
        id
        name
        bornCountry {
          name
        }
        confidential
      }
      ... on Artwork {
        id
        imagesSummary {
          imgT
        }
        artists {
          name
        }
        title
        description
        medium
        dimensions
      }
      ... on Contact {
        id
        companyName: company
        country {
          id
          name
        }
        county
        email
        img
        name
        salesUser {
          id
          name
        }
        title
        type {
          id
          name
        }
      }
      ... on Company {
        ${company(item)}
      }
      ... on Exhibition {
        id
        artFair
        contact {
          company
          name
        }
          exhibitionWorks {
            ${exhibitionWork}
          }
        endDay
        endMonth
        endYear
        startDay
        startMonth
        startYear
        title
      }
      ... on Image {
        ${image(item)}
      }
      ... on Instance {
        ${instance(item)}
      }
      ... on Invoice {
        id
        date
        company {
          name
        }
        type {
          id
          description
        }
        approved
        contact {
          name
          img
        }
        companyName
        number
        reference
        currency {
          code
        }
        invoiceTotals
        dueDate
      }
      ... on Offer {
        id
        artwork {
          title
          artists {
            name
          }
          imagesSummary {
            imgT
          }
        }
        updatedAt
        date
        status {
          description
        }
        currency {
          id
          code
        }
        tbiAmount
        dateClosed
        notes
        user
      }
      ... on WarehouseItem {
        id
        category {
          description
        }
        description
        destroyed
        weightType {
          id
          name
        }
        weight
      }
      ... on Job {
        ${job(item)}
      }
    }`
      : ""
  }
  listId
  meta
`;

export default ListItems;
