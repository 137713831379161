import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal } from "../../../../../../../components/ui";
import { GridInputs } from "../../../../../../../components/Functional";
import { useFormik } from "formik";
import { FETCH_CURRENCIES } from "../../../../../../../graphql/query/Currency";
import { FETCH_BANK_ACCOUNTS_V2 as fetchBankAccountsV2 } from "../../../../../../../graphql/query/BankAccount";
import { CREATE_BANK_ACCOUNT } from "../../../../../../../graphql/mutation/BankAccount";
import * as Yup from "yup";
import { CreateEditFormInputs } from "./CreateEditFormInputs";
import useUser from "../../../../../../../hooks/useUser";

const AddBankAccountBody = props => {
  const { closeModal, company } = props || {};

  const [createBankAccount, { loading }] = useMutation(CREATE_BANK_ACCOUNT, {
    refetchQueries: [fetchBankAccountsV2({ currency: {} })],
  });

  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const formik = useFormik({
    initialValues: {
      holder: company?.name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(
      {
        bank: Yup.string().required("Please enter the bank name"),
        number: Yup.number()
          .typeError("Account number can only contain numbers")
          .when(["iban", "swift"], ([iban, swift], schema) => {
            return iban !== undefined && swift !== undefined
              ? schema.min(0)
              : schema.required("Please enter an account number");
          }),
        sortCode: Yup.number()
          .typeError("Sort code can only contain numbers")
          .when(["iban", "swift"], ([iban, swift], schema) => {
            return iban !== undefined && swift !== undefined
              ? schema.notRequired()
              : schema.required("Please enter a sort code");
          }),
        holder: Yup.string().required("Please enter the account holder name"),
        currencyId: Yup.number().required("Please select a currency"),
        iban: Yup.string().when(
          ["number", "sortCode"],
          ([number, sortCode], schema) => {
            return number !== undefined && sortCode !== undefined
              ? schema.notRequired()
              : schema.required("Please enter an IBAN");
          },
        ),
        swift: Yup.string().when(
          ["number", "sortCode"],
          ([number, sortCode], schema) => {
            return number !== undefined && sortCode !== undefined
              ? schema.notRequired()
              : schema.required("Please enter a swift code");
          },
        ),
      },
      [
        ["iban", "swift"],
        ["iban", "sortCode"],
        ["iban", "number"],
        ["swift", "iban"],
        ["swift", "number"],
        ["swift", "sortCode"],
      ],
    ),
    onSubmit: async values => {
      createBankAccount({
        variables: {
          input: {
            companyId: company.id,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createBankAccount: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          closeModal();
        }
      });
    },
  });

  const inputProps = {
    formik,
    ...CreateEditFormInputs({ currencies }),
  };

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new bank account</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid">
        <GridInputs {...inputProps} />
      </div>
    </div>
  );
};

const AddBankAccountModal = props => {
  const { hasPermission } = useUser();
  const disabled = !hasPermission("UPDATE_BANK_ACCOUNT");

  const modalProps = {
    body: AddBankAccountBody,
    closeOnBackdrop: false,
    hideCloseButton: true,
    scale: "md",
    ...props,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Bank Account" disabled={disabled} />
    </Modal>
  );
};

export { AddBankAccountModal, AddBankAccountBody };
