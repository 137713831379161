import { useQuery } from "@apollo/client";
import { Button } from "../../../../components/ui";
import { FETCH_EXHIBITION_WORKS as fetchExhibitionWorks } from "../../../../graphql/query/ExhibitionWork";

const CopyDescription = ({ closeModal, exhibitionId }) => {
  const { data: { exhibitionWorks = {} } = {}, loading } = useQuery(
    fetchExhibitionWorks,
    {
      variables: {
        input: {
          limit: 50,
          offset: 0,
          exhibitionId: exhibitionId,
        },
      },
    },
  );
  const handleCopyDescription = () => {
    const descriptions = exhibitionWorks?.edges
      ?.map(work => work?.instance?.description)
      .join("\n");
    navigator.clipboard.writeText(descriptions);
    closeModal();
  };

  return (
    <div className="flex mt-4">
      <Button
        className="mr-3"
        label={"Cancel"}
        action={"default"}
        onClick={() => {
          closeModal?.();
        }}
      />
      <Button
        disabled={loading}
        label={"Copy description"}
        onClick={handleCopyDescription}
      />
    </div>
  );
};

export default CopyDescription;
