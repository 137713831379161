import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components/ui";
import { DUPLICATE_EXHIBITION } from "../../../../graphql/mutation/Exhibition";
import { FETCH_EXHIBITIONS as fetchExhibitions } from "../../../../graphql/query/Exhibition";

const DuplicateExhibition = props => {
  const { closeModal, exhibition } = props || {};
  const navigate = useNavigate();
  const [duplicateExhibition, { loading }] = useMutation(
    DUPLICATE_EXHIBITION(),
    {
      refetchQueries: [fetchExhibitions()],
    },
  );

  const handleDuplicate = () => {
    const exhibitionToDuplicate = {
      ...exhibition,
    };
    delete exhibitionToDuplicate.__typename;
    delete exhibitionToDuplicate.contact;
    delete exhibitionToDuplicate.exhibitionWorks;

    duplicateExhibition({
      variables: {
        input: {
          ...exhibitionToDuplicate,
        },
      },
    }).then(resp => {
      const {
        data: { duplicateExhibition: { success, exhibition } = {} },
      } = resp || {};
      if (success) {
        navigate(`/exhibition/${exhibition.id}/details`);
        closeModal();
      }
    });
  };

  return (
    <div className="">
      <h3 className="text-lg font-bold leading-6 text-gray-900">
        Duplicate Exhibition?
      </h3>
      <div className="mt-4 flex">
        <Button
          action="default"
          className="mr-3"
          label="Cancel"
          onClick={() => {
            closeModal();
          }}
        />
        <Button
          label={loading ? "Duplicating" : "Duplicate"}
          disabled={loading}
          onClick={handleDuplicate}
        />
      </div>
    </div>
  );
};

export default DuplicateExhibition;
