import { Table } from "../../../../../../components/Functional";
import { Button, Modal } from "../../../../../../components/ui";
import { FETCH_EXHIBITION_DOCUMENTS } from "../../../../../../graphql/query/ExhibitionDocument";
import SectionHeader from "../../components/SectionHeader";
import Actions from "./components/Actions";
import AddDocuments from "./components/AddDocument";

const Documents = props => {
  const { exhibition, exhibitionId } = props || {};

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "exhibitionDocuments",
    headers: [
      { label: "File Name", name: "fileName", type: "label" },
      { label: "File Type", name: "fileType", type: "label" },
      { label: "Date", name: "updatedAt", type: "date" },
      {
        label: "",
        name: "action",
        type: "custom",
        component: Actions,
      },
    ],
    inputs: [{ label: "Name", name: "name", type: "type", view: "partial" }],
    FETCH_QUERY: FETCH_EXHIBITION_DOCUMENTS,
    variables: {
      exhibitionId: +exhibitionId,
    },
    className: "sm:px-0 lg:px-0",
  };

  const modalProps = {
    body: AddDocuments,
    closeOnBackdrop: true,
    exhibition,
    hideCloseButton: true,
    scale: "md",
  };

  const actions = [
    {
      component: () => (
        <Modal {...modalProps}>
          <Button label="Upload" action="black" />
        </Modal>
      ),
    },
  ];

  return (
    <div className="w-full">
      <SectionHeader title="Documents" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Documents;
