import { FETCH_ARTISTS_LIST as fetchArtistsList } from "../../../../../graphql/query/Artist";
import { artistFilterStore } from "../../../components/useArtistFilters";

const SearchedArtists = props => {
  const tableProps = {
    dataKey: "artistsV2",
    FETCH_QUERY: fetchArtistsList({}),
    variables: {
      ...artistFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedArtists;
