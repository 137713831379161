import { Table } from "../../../../../../components/Functional";
import { FETCH_CONSIGNMENTS } from "../../../../../../graphql/query/Consignment";
import SectionHeader from "../../components/SectionHeader";

const RefLink = props => {
  const { values: { id } = {} } = props;

  return (
    <a
      href={`/consignment/${id}/form`}
      target="_blank"
      rel="noopener noreferrer"
      className="underline"
    >
      {id}
    </a>
  );
};

const Consignments = props => {
  const { exhibition } = props || {};

  const tableProps = {
    className: "sm:px-0 lg:px-0",
    dataKey: "consignments",
    FETCH_QUERY: FETCH_CONSIGNMENTS({
      type: {},
      contact: {},
      consigner: {},
    }),
    headers: [
      {
        label: "Type",
        type: "label",
        name: "type.description",
      },
      {
        label: "Reference",
        type: "custom",
        component: RefLink,
      },
      { label: "Date", name: "date", type: "date" },
      { label: "Due Date", name: "due", type: "date" },
      {
        label: "Consigned To",
        type: "label",
        name: "contact.name",
      },
      {
        label: "Consigned By",
        type: "label",
        name: "consigner.name",
      },
      {
        label: "Notes",
        type: "label",
        name: "notes",
      },
    ],
    skip: () => !exhibition?.id,
    variables: {
      exhibitionId: exhibition?.id,
      order: ["id", "DESC"],
    },
    variant: "dynamicHeight",
  };

  return (
    <div className="w-full">
      <SectionHeader title="Consignments" />
      <div className="w-full mb-6">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export default Consignments;
