import { FETCH_SHIPMENTS_LIST as fetchShipmentsList } from "../../../../../graphql/query/Shipment";
import { shipmentFilterStore } from "../../../components/useShipmentFilters";

const SearchedShipments = props => {
  const tableProps = {
    dataKey: "shipmentsV2",
    FETCH_QUERY: fetchShipmentsList,
    limit: 50,
    variables: {
      ...shipmentFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedShipments;
