/* eslint-disable max-len */
const ArtistIcon = props => (
  <svg
    width={34}
    height={34}
    viewBox="419.067 138.674 1476.186 1460.093"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="none"
    stroke="currentColor"
    {...props}
  >
    <g
      transform="translate(0.000000,1782.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path d="M17470 16254 c-128 -16 -272 -61 -376 -119 -104 -58 -178 -119 -397 -327 -116 -111 -293 -279 -392 -372 -99 -94 -220 -210 -270 -257 -49 -47 -358 -340 -685 -650 -1237 -1172 -1571 -1490 -1604 -1527 -18 -20 -412 -518 -875 -1107 l-843 -1070 -96 -17 c-228 -41 -421 -129 -637 -292 -111 -83 -304 -274 -381 -376 -89 -117 -191 -328 -254 -525 -85 -264 -100 -307 -137 -382 -64 -131 -176 -231 -380 -337 -90 -47 -141 -91 -187 -161 -170 -256 -63 -598 222 -705 109 -41 363 -110 534 -144 354 -71 604 -96 908 -91 453 8 811 106 1099 300 171 114 313 260 443 454 154 230 240 465 271 738 l12 112 495 432 c272 237 738 643 1035 902 297 259 560 491 585 516 25 25 160 181 301 346 140 165 761 894 1379 1620 618 726 1145 1347 1170 1380 94 125 165 297 191 465 15 100 6 303 -20 405 -94 382 -408 689 -786 770 -72 16 -262 27 -325 19z m163 -969 c30 -31 37 -62 23 -104 -9 -25 -604 -730 -1656 -1962 -69 -80 -159 -186 -201 -235 -41 -49 -194 -228 -338 -397 l-263 -308 -136 130 c-75 71 -169 162 -209 203 l-72 73 882 836 c485 460 1108 1050 1384 1313 546 517 531 505 586 451z m-3103 -3681 c0 -1 -48 -44 -107 -95 -448 -390 -596 -520 -653 -570 -239 -211 -665 -579 -670 -579 -3 0 -34 29 -69 64 l-63 64 314 398 c172 220 437 555 587 746 l273 348 194 -187 c107 -103 194 -188 194 -189z m-2314 -1744 c78 -38 205 -159 237 -225 65 -133 59 -290 -17 -452 -90 -190 -216 -312 -385 -373 -140 -51 -233 -64 -473 -65 -119 0 -220 4 -224 8 -4 4 -1 20 8 35 34 58 99 228 172 454 87 268 110 311 227 425 150 147 265 214 380 222 9 1 43 -12 75 -29z" />
      <path d="M9945 15455 c-826 -86 -1692 -411 -2423 -909 -73 -50 -188 -127 -256 -171 -414 -271 -713 -516 -1029 -845 -671 -697 -1206 -1613 -1531 -2621 -132 -407 -203 -782 -240 -1254 -15 -191 -18 -745 -5 -935 60 -879 263 -1720 617 -2550 84 -199 303 -632 416 -825 168 -285 453 -698 625 -905 559 -671 1123 -1153 1805 -1542 648 -369 1402 -623 2166 -728 85 -11 169 -23 185 -25 40 -6 244 -24 385 -35 63 -5 309 -7 545 -5 428 3 543 10 855 50 1225 158 2288 617 3072 1329 146 133 426 420 545 561 738 868 1189 1863 1288 2839 18 176 12 611 -10 766 -114 811 -527 1420 -1180 1741 -235 115 -439 167 -690 176 -313 11 -554 -40 -852 -183 -276 -131 -378 -210 -440 -340 -92 -193 -52 -409 100 -550 70 -65 135 -98 230 -118 130 -27 241 -3 383 84 361 222 651 225 974 11 486 -320 663 -983 490 -1833 -118 -575 -405 -1185 -819 -1734 -285 -380 -688 -778 -1021 -1011 -908 -633 -2219 -939 -3540 -827 -1344 114 -2420 614 -3347 1555 -1047 1064 -1725 2602 -1834 4162 -15 225 -7 762 16 967 39 359 96 616 216 966 410 1202 1140 2203 2039 2795 91 59 219 146 285 193 583 409 1229 681 1860 781 373 59 801 48 1150 -30 482 -107 1001 -382 1281 -677 134 -141 211 -263 279 -443 68 -180 128 -256 254 -319 153 -78 331 -67 476 30 73 48 120 103 163 193 32 66 36 84 40 175 4 96 3 106 -34 215 -45 138 -150 353 -231 476 -224 340 -538 619 -978 870 -431 247 -900 403 -1407 471 -168 22 -693 28 -873 9z" />
      <path d="M9325 13413 c-516 -67 -954 -375 -1180 -828 -62 -124 -105 -249 -137 -392 -19 -84 -22 -128 -22 -308 0 -194 2 -219 28 -330 154 -661 679 -1129 1341 -1196 466 -47 964 152 1271 509 415 481 497 1155 211 1719 -262 518 -787 838 -1358 832 -68 -1 -137 -4 -154 -6z m310 -958 c93 -25 174 -73 250 -150 81 -81 127 -162 155 -271 91 -353 -156 -709 -507 -731 -171 -12 -310 41 -433 164 -118 119 -173 251 -174 418 -1 323 247 583 559 584 51 1 117 -6 150 -14z" />
      <path d="M7786 10109 c-535 -55 -1019 -413 -1232 -911 -94 -220 -129 -413 -121 -668 6 -192 27 -304 87 -475 157 -440 518 -791 960 -933 179 -57 246 -67 465 -67 158 0 218 4 285 19 310 68 558 202 775 420 184 185 290 357 369 597 66 200 79 299 73 537 -5 208 -15 266 -73 447 -114 349 -383 674 -708 854 -255 141 -592 210 -880 180z m328 -968 c188 -66 325 -218 376 -417 94 -365 -181 -728 -550 -728 -151 1 -281 55 -390 164 -75 74 -111 133 -147 240 -30 88 -36 229 -14 320 54 223 239 401 461 444 55 10 206 -2 264 -23z" />
      <path d="M13248 7540 c-449 -47 -853 -292 -1105 -670 -258 -386 -328 -883 -186 -1325 172 -533 588 -915 1134 -1037 90 -20 131 -23 309 -23 163 0 222 4 290 19 613 134 1058 581 1192 1200 19 86 22 132 22 306 0 174 -3 220 -22 306 -104 478 -393 859 -811 1068 -247 125 -557 183 -823 156z m264 -955 c95 -20 183 -64 255 -129 79 -72 130 -149 167 -256 25 -72 29 -100 30 -185 0 -289 -187 -523 -464 -581 -270 -56 -545 113 -642 395 -27 79 -32 254 -9 336 41 147 151 291 272 358 133 73 252 92 391 62z" />
      <path d="M9630 7034 c-391 -48 -700 -199 -960 -471 -197 -205 -328 -457 -392 -753 -30 -140 -32 -455 -5 -593 124 -618 579 -1083 1189 -1218 119 -26 359 -36 483 -20 353 47 669 208 914 467 432 456 540 1139 271 1711 -184 391 -495 669 -899 803 -64 21 -156 46 -206 55 -89 15 -328 27 -395 19z m241 -954 c30 -6 87 -26 128 -46 350 -165 441 -642 178 -931 -225 -246 -590 -249 -823 -6 -143 148 -196 390 -130 588 37 109 79 175 158 250 134 127 309 179 489 145z" />
    </g>
  </svg>
);

export default ArtistIcon;
