/* eslint-disable new-cap */
import { gql } from "@apollo/client";
import { Instance as instance } from "../fragments";

const FETCH_INSTANCE = props => gql`
  query FetchInstance($id: Int) {
    instance(id: $id) {
      ${instance(props)}
    }
  }
`;

const FETCH_INSTANCES = props => gql`
  query FetchInstances($input: InstanceFilterInput!) {
    instances(input: $input) {
      count
      edges {
        ${instance(props)}
      }
      error
    }
  }
`;

const FETCH_INSTANCES_V2 = props => gql`
  query FetchInstancesV2($input: AdvInstanceFilterInput!) {
    instancesV2(input: $input) {
      count
      edges {
        ${instance(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_INSTANCES_LIST = gql`
  query FetchInstancesV2($input: AdvInstanceFilterInput!) {
    instancesV2(input: $input) {
      count
      edges {
        id
        edition {
          artwork {
            artists {
              name
            }
            imagesSummary {
              imgT
            }
            title
          }
          number
          year
        }
        editionNumber
        name
        status {
          name
        }
      }
    }
  }
`;

export {
  FETCH_INSTANCE,
  FETCH_INSTANCES,
  FETCH_INSTANCES_V2,
  FETCH_INSTANCES_LIST,
};
