import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, getInput } from "../../../../components/ui";
import { CREATE_REPORT_TEMPLATE } from "../../../../graphql/mutation/ReportTemplate";
import { FETCH_REPORT_TEMPLATES } from "../../../../graphql/query/ReportTemplate";

const CreateReportTemplate = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const [createReportTemplate, { loading }] = useMutation(
    CREATE_REPORT_TEMPLATE,
    { refetchQueries: [FETCH_REPORT_TEMPLATES] },
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a name"),
      meta: Yup.object().shape({
        type: Yup.string().required("Please select a type").nullable(),
      }),
    }),
    onSubmit: async values => {
      createReportTemplate({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createReportTemplate: { success, reportTemplate } = {} },
        } = resp || {};
        if (success) {
          navigate(`/reports/${reportTemplate?.id}/edit`);
          formik?.resetForm();
          closeModal();
        }
      });
    },
  });

  const inputs = [
    { label: "Name", name: "name", type: "text" },
    {
      label: "Type",
      name: "meta.type",
      type: "multi-select",
      options: [
        { label: "Artwork", value: "artwork" },
        { label: "Consignment", value: "consignment" },
        { label: "Contact", value: "contact" },
        { label: "Instance", value: "instance" },
        { label: "Invoice", value: "invoice" },
        { label: "Job", value: "job" },
        { label: "Offer", value: "offer" },
        { label: "Shipment", value: "shipment" },
        { label: "Warehouse", value: "warehouseitem" },
        { label: "Exhibition", value: "exhibition" },
      ],
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>New Report Template</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => (formik?.resetForm?.(), closeModal())}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-x-16 gap-y-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default CreateReportTemplate;
