import {
  ClipboardIcon,
  HomeIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import ExhibitionDetails from "./pages/Details";
import LogoutIcon from "../../../../assets/icons/LogoutIcon";
import Consignments from "./pages/Consignments";
import Documents from "./pages/Documents";

const routes = [
  {
    href: "/exhibition",
    icon: HomeIcon,
    name: "All Exhibitions",
  },
  {
    element: ExhibitionDetails,
    href: "details",
    icon: ClipboardIcon,
    name: "Details",
  },
  {
    element: Consignments,
    href: "consignments",
    icon: LogoutIcon,
    name: "Consignments",
  },
  {
    element: Documents,
    href: "documents",
    icon: PaperClipIcon,
    name: "Documents",
  },
];

export default routes;
