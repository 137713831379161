import { getInput } from "../../../../../../../../../components/ui";
import useUser from "../../../../../../../../../hooks/useUser";

const DetailFormConsignee = ({
  formik,
  disableConfig,
  submitOnBlur = false,
  consignment,
}) => {
  const { hasPermission } = useUser();
  const inputs = [
    {
      label: "Consignee Name",
      name: "contactId",
      type: "contactSearch",
      submitOnBlur,
      disabled:
        !hasPermission("UPDATE_CONSIGNMENT") ||
        consignment?.noOfConsignmentWorks > 0,
      showType: true,
    },
    {
      label: "Consignee Company",
      name: "consigneeCompany",
      type: "text",
      ...disableConfig,
      submitOnBlur,
    },
    ...(hasPermission("READ_EXHIBITION")
      ? [
          {
            label: "Exhibition",
            name: "exhibitionId",
            type: "exhibitionSearch",
            submitOnBlur,
            disabled: !hasPermission("UPDATE_CONSIGNMENT"),
          },
          {
            label: "Exhibition Dates",
            name: "exhibition.fullDate",
            type: "text",
            ...disableConfig,
            submitOnBlur,
            disabled: !hasPermission("UPDATE_CONSIGNMENT"),
          },
        ]
      : [
          {
            label: "Exhibition",
            name: "mockExhibition",
            type: "text",
            disabled: true,
            className: "opacity-50 [&_*]:!cursor-not-allowed",
          },
          {
            label: "Exhibition Dates",
            name: "mockExhibitionDate",
            disabled: true,
            type: "text",
            className: "opacity-50 [&_*]:!cursor-not-allowed",
          },
        ]),
  ];

  return (
    <div className={`grid gap-4`}>
      <div>Consignee</div>
      {inputs?.map((item, index) => {
        const inputProps = {
          formik,
          ...item,
        };

        return (
          <div key={index} className="grid-cols-1 gap-4">
            {getInput(inputProps)}
          </div>
        );
      })}
    </div>
  );
};

export default DetailFormConsignee;
