const exportModule = {
  name: "Exhibition",
  sections: [
    {
      fields: [
        { label: "Title", name: "title" },
        { label: "Type", name: "type" },
        { label: "Start Date", name: "startDate" },
        { label: "End Date", name: "endDate" },
        { label: "Full Date", name: "fullDate" },
        { label: "Sage Code", name: "sageCode" },
        { label: "Notes", name: "notes" },
      ],
      label: "Details",
      name: "details",
    },
    {
      fields: [
        { label: "Forename", name: "contact.forename" },
        { label: "Surname", name: "contact.surname" },
        { label: "Company", name: "contact.contactCompany.name" },
        { label: "Job title", name: "contact.title" },
        { label: "Address", name: "contact.address" },
        { label: "Town", name: "contact.town" },
        { label: "County", name: "contact.county" },
        { label: "Postcode", name: "contact.postcode" },
        { label: "Country", name: "contact.country.name" },
        { label: "Primary Sales Contact", name: "contact.salesUser.name" },
        { label: "Secondary Sales Contact", name: "contact.salesUser2.name" },
        { label: "Exhibition Liason", name: "contact.exhUser.name" },
        { label: "ID", name: "id" },
      ],
      label: "Contact",
      name: "contact",
    },
    {
      fields: [
        { label: "ID", name: "ExhibitionWorks.id" },
        {
          label: "Artist",
          name: "ExhibitionWorks.Instance.Edition.Artwork.Artists.name",
        },
        {
          label: "Title",
          name: "ExhibitionWorks.Instance.Edition.Artwork.title",
        },
        {
          label: "Description",
          name: "ExhibitionWorks.Instance.Edition.Artwork.description",
        },
        {
          label: "Dimensions",
          name: "ExhibitionWorks.Instance.Edition.Artwork.dimensions",
        },
        {
          label: "Goods Code",
          name: "ExhibitionWorks.Instance.Edition.Artwork.goodsCode",
        },
        {
          label: "Precis",
          name: "ExhibitionWorks.Instance.Edition.Artwork.precis",
        },
        {
          label: "Category",
          name: "ExhibitionWorks.Instance.Edition.Artwork.ArtworkCategory.name",
        },
        {
          label: "Year",
          name: "ExhibitionWorks.Instance.Edition.year",
        },
        { label: "Technical Notes", name: "ExhibitionWorks.technicalNotes" },
      ],
      label: "Artworks",
      name: "artworks",
    },
  ],
};

export default exportModule;
