import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { Button, getInput } from "../../../components/ui";
import { DUPLICATE_ARTWORK as duplicateArtwork } from "../../../graphql/mutation/Artwork";
import {
  FETCH_ARTWORKS as fetchArtworks,
  FETCH_ARTWORKS_LIST as fetchArtworksList,
} from "../../../graphql/query/Artwork";

const DuplicateArtwork = props => {
  const { closeModal } = props || {};
  const navigate = useNavigate();
  const [duplicateArtWork, { loading }] = useMutation(duplicateArtwork(), {
    refetchQueries: [fetchArtworks(), fetchArtworksList()],
  });

  const formik = useFormik({
    initialValues: { noOfCopies: 0 },
    validationSchema: Yup.object({
      noOfCopies: Yup.number()
        .min(1, "At least one copy is required")
        .max(500, "Maximum 500 copies allowed"),
    }),
    onSubmit: async values => {
      duplicateArtWork({
        variables: {
          input: {
            ...values,
            id: props?.artwork?.id,
          },
        },
      }).then(resp => {
        const {
          data: { duplicateArtwork: { success } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          navigate(`/artworks`);
          closeModal();
        }
      });
    },
  });

  const inputs = [
    {
      label: "How many copies would you like to create?",
      name: "noOfCopies",
      type: "number",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Duplicate this Record</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Duplicate"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default DuplicateArtwork;
