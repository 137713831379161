import { FETCH_JOBS_LIST as fetchJobsList } from "../../../../../graphql/query/Job";
import { jobFilterStore } from "../../../components/useJobsFilters";

const SearchedJobs = props => {
  const tableProps = {
    dataKey: "jobsV2",
    FETCH_QUERY: fetchJobsList,
    limit: 50,
    variables: {
      ...jobFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedJobs;
