import { gql } from "@apollo/client";
import { WarehouseItem as warehouseItem } from "../fragments";

const FETCH_WAREHOUSE_ITEM = props => gql`
  query FetchWarehouseItem($id: Int!) {
    warehouseItem(id: $id) {
      ${warehouseItem(props)}
    }
  }
`;

const FETCH_WAREHOUSE_ITEMS_V2 = props => gql`
  query FetchWarehouseItemsV2($input: AdvWarehouseItemFilterInput!) {
    warehouseItemsV2(input: $input) {
      count
      edges {
        ${warehouseItem(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_WAREHOUSE_ITEMS_LIST = props => gql`
  query FetchWarehouseItemsList($input: AdvWarehouseItemFilterInput!) {
    warehouseItemsV2(input: $input) {
      count
      edges {
        id
        category {
          description
        }
        description
        destroyed
        weightType {
          id
          name
        }
        weight
      }
      sqlQuery
    }
  }
`;

const FETCH_WAREHOUSE_MGROUP = gql`
  query FetchWarehouseMGroup {
    warehouseMGroup {
      mgroups
    }
  }
`;

export {
  FETCH_WAREHOUSE_ITEM,
  FETCH_WAREHOUSE_ITEMS_V2,
  FETCH_WAREHOUSE_ITEMS_LIST,
  FETCH_WAREHOUSE_MGROUP,
};
