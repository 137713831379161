import { useMutation, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, Spinner } from "../../../../components/ui";
import { GridInputs } from "../../../../components/Functional";
import { BATCH_UPDATE_ARTWORK as batchUpdateArtworkMutation } from "../../../../graphql/mutation/Artwork";
import { FETCH_ARTWORK_CATEGORIES } from "../../../../graphql/query/ArtworkCategory";
import { useEffect } from "react";

const BatchEditBody = props => {
  const {
    closeModal,
    isBatchEditOpen = false,
    ids,
    listId,
    search,
    select,
  } = props || {};
  const [batchUpdateArtwork, { loading }] = useMutation(
    batchUpdateArtworkMutation(),
  );

  const [
    fetchArtworkCategoriessLazily,
    {
      loading: artworkCategoriesLoading,
      data: { artworkCategories = [] } = {},
    },
  ] = useLazyQuery(FETCH_ARTWORK_CATEGORIES);

  useEffect(() => {
    if (isBatchEditOpen) {
      fetchArtworkCategoriessLazily();
    }
  }, [isBatchEditOpen]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      // fileName: Yup.string().required("Please enter file name"),
    }),
    onSubmit: async values => {
      const { series, categoryId, medium, authenticity } = values || {};
      await batchUpdateArtwork({
        variables: {
          input: {
            ...(select === "all"
              ? { ...(listId ? { listId } : { search }) }
              : { ids }),
            authenticity,
            categoryId,
            medium,
            series,
          },
        },
      }).then(res => {
        const { batchUpdateArtwork: { success } = {} } = res?.data || {};
        if (success) {
          formik?.resetForm?.();
          closeModal?.();
        }
      });
    },
  });

  const inputs = {
    className: "grid grid-cols-2 gap-8",
    inputs: [
      {
        label: "Series",
        name: "series",
        type: "text",
      },
      {
        label: "Category",
        name: "categoryId",
        options: artworkCategories?.map(item => ({
          label: item.name,
          value: item.id,
        })),
        type: "multi-select",
      },
      {
        label: "Medium",
        name: "medium",
        type: "textarea",
      },
      {
        label: "Authenticity",
        name: "authenticity",
        type: "textarea",
      },
    ],
  };

  const gridInputProps = {
    formik,
    ...inputs,
  };

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className={`p-4`}>
      <div className={`flex flex-row items-center justify-between`}>
        <div className="flex flex-col">
          <div className={`text-lg`}>Batch Edit</div>
          <div className={`text-sm`}>{`This will update ${
            select === "all" ? "All" : ids?.length
          } Records`}</div>
        </div>
        <div className={`flex gap-4`}>
          <Button
            className={`!w-22`}
            action="default"
            label="Cancel"
            onClick={() => (formik?.resetForm?.(), closeModal())}
          />
          <Button
            loading={loading}
            className={`!w-22 flex items-center`}
            label={loading ? "Updating..." : "Update"}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      {artworkCategoriesLoading ? (
        <div className="flex h-[30dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="my-5 grid">
          <GridInputs {...gridInputProps} />
        </div>
      )}
    </div>
  );
};

export default BatchEditBody;
