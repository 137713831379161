import { gql } from "@apollo/client";

const UPLOAD_EXHIBITION_DOCUMENTS = gql`
  mutation UploadExhibitionDocuments($exhibitionId: Int!, $files: [Upload]!) {
    uploadExhibitionDocuments(exhibitionId: $exhibitionId, files: $files) {
      success
      error
      response
    }
  }
`;

const DELETE_EXHIBITION_DOCUMENT = gql`
  mutation DeleteExhibitionDocument($input: DeleteExhibitionDocumentInput!) {
    deleteExhibitionDocument(input: $input) {
      success
      error
    }
  }
`;

export { UPLOAD_EXHIBITION_DOCUMENTS, DELETE_EXHIBITION_DOCUMENT };
