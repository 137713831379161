import { useMutation, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, getInput, Spinner } from "../../../components/ui";
import { FETCH_WAREHOUSE_CATEGORIES } from "../../../graphql/query/WarehouseCategory";
import { CREATE_WAREHOUSE_ITEM as createWarehouseItemMutation } from "../../../graphql/mutation/WarehouseItem";
import { useEffect } from "react";

const NewWarehouse = props => {
  const { closeModal, isCreateOpen = false } = props || {};
  const navigate = useNavigate();
  const [createWarehouseItem, { loading }] = useMutation(
    createWarehouseItemMutation(),
    { refetchQueries: ["FetchWarehouseItemsList"] },
  );

  const [
    fetchWarehouseCategoriesLazily,
    {
      loading: warehouseCategoriesLoading,
      data: { warehouseCategories = [] } = {},
    },
  ] = useLazyQuery(FETCH_WAREHOUSE_CATEGORIES);

  useEffect(() => {
    if (isCreateOpen) {
      fetchWarehouseCategoriesLazily();
    }
  }, [isCreateOpen]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape(
      {
        categoryId: Yup.number()
          .nullable(true)
          .when("itemDescription", {
            is: undefined,
            then: schema => schema.required("Please select category"),
          }),
        itemDescription: Yup.string().when("categoryId", {
          is: undefined,
          then: schema => schema.required("Please enter item description"),
        }),
      },
      ["itemDescription", "categoryId"],
    ),
    onSubmit: async values => {
      // If there's no categoryId set then set the categoryId to 0
      if (!values?.categoryId) {
        values.categoryId = 0;
      }

      createWarehouseItem({
        variables: {
          input: {
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createWarehouseItem: { success, warehouseItem } = {} },
        } = resp || {};
        if (success) {
          closeModal();
          formik?.resetForm();
          navigate(`/warehouse/${warehouseItem?.id}/details`);
        }
      });
    },
  });

  const inputs = [
    {
      label: "Category",
      name: "categoryId",
      type: "multi-select",
      options: warehouseCategories?.map(item => ({
        label: item?.description,
        value: item?.id,
      })),
    },
    {
      label: "Item Description",
      name: "itemDescription",
      type: "textarea",
    },
    {
      label: "Instance",
      name: "instanceId",
      type: "instanceSearch",
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4 p-10">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>New Warehouse Item</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Add"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      {warehouseCategoriesLoading ? (
        <div className="flex h-[30dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="w-50 grid grid-cols-2 gap-16">
          {inputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export default NewWarehouse;
