import { Table } from "../../../../../../components/Functional";
import { getInput } from "../../../../../../components/ui";
import { UPDATE_EXHIBITION_WORK } from "../../../../../../graphql/mutation/ExhibitionWork";
import { FETCH_EXHIBITION_WORKS } from "../../../../../../graphql/query/ExhibitionWork";
import useUser from "../../../../../../hooks/useUser";
import SectionHeader from "../../components/SectionHeader";
import Actions from "./components/Actions";
import AddWork from "./components/AddWork";

const Infos = ({ disabled, formik, submitOnBlur = false }) => {
  const infoInputs = [
    {
      label: "Title",
      name: "title",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Type",
      name: "type",
      type: "multi-select",
      options: [
        { label: "Solo", value: "Solo" },
        { label: "Group", value: "Group" },
        { label: "Artfair", value: "Artfair" },
        { label: "External", value: "External" },
        { label: "Cancelled", value: "Cancelled" },
      ],
      submitOnBlur,
      disabled,
    },
    {
      label: "Contact",
      name: "contactId",
      type: "contactSearch",
      redirect: true,
      submitOnBlur,
      disabled,
    },
    {
      label: "Company",
      name: "contact.company",
      type: "text",
      disabled: true,
    },
    {
      label: "Sage Code",
      name: "sageCode",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Curator",
      name: "curator",
      type: "text",
      submitOnBlur,
      disabled,
    },
    {
      label: "Notes",
      name: "notes",
      type: "textarea",
      rows: 4,
      submitOnBlur,
      className: "col-span-2",
      disabled,
    },
  ];

  const renderInfoInputs = infoInputs?.map((item, index) => {
    const { className } = item;
    const inputProps = {
      disabled,
      formik,
      ...item,
    };

    return (
      <div key={index} className={className}>
        {getInput(inputProps)}
      </div>
    );
  });

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "Feburary" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const dateInputs = [
    {
      label: "Start Date",
      inputs: [
        {
          name: "startDay",
          type: "number",
          min: 1,
          max: 31,
          submitOnBlur,
          allowEmpty: true,
          disabled,
        },
        {
          name: "startMonth",
          type: "multi-select",
          options: monthOptions,
          submitOnBlur,
          labelClass: "mt-[4px]",
          disabled,
        },
        {
          name: "startYear",
          type: "number",
          min: 1900,
          max: 2099,
          submitOnBlur,
          allowEmpty: true,
          disabled,
        },
      ],
    },
    {
      label: "End Date",
      inputs: [
        {
          name: "endDay",
          type: "number",
          min: 1,
          max: 31,
          submitOnBlur,
          allowEmpty: true,
          disabled,
        },
        {
          name: "endMonth",
          type: "multi-select",
          options: monthOptions,
          submitOnBlur,
          labelClass: "mt-[4px]",
          disabled,
        },
        {
          name: "endYear",
          type: "number",
          min: 1900,
          max: 2099,
          submitOnBlur,
          allowEmpty: true,
          disabled,
        },
      ],
    },
  ];

  const renderDateInputs = dateInputs?.map((block, index) => {
    const blockEle = block.inputs.map((item, index) => {
      const inputProps = {
        disabled,
        formik,
        ...item,
      };

      return <div key={index}>{getInput(inputProps)}</div>;
    });

    return (
      <div key={"block_" + index} className="mb-4">
        <span>{block.label}</span>
        <div className="grid grid-cols-3 items-start gap-2">{blockEle}</div>
      </div>
    );
  });

  return (
    <div className="grid grid-cols-2 gap-x-20 gap-y-4">
      <div className="my-10 grid w-full grid-cols-2 items-center gap-x-20 gap-y-4">
        {renderInfoInputs}
      </div>
      <div>{renderDateInputs}</div>
    </div>
  );
};

const ExhibitionWorks = props => {
  const { exhibition, disabled } = props;
  const { hasPermission } = useUser();

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "exhibitionWorks",
    headers: [
      {
        label: "Ref",
        name: "instanceId",
        type: "input",
        inputType: "instanceSearch",
        disabledIfFilled: true,
        disabled,
        redirect: true,
      },
      {
        label: "Title",
        type: "label",
        name: "instance.edition.artwork.title",
      },
      {
        label: "Edition",
        type: "custom",
        component: props => {
          const { values } = props;
          return (
            <div>
              {values?.instance
                ? `${values?.instance?.editionNumber} /
              ${values?.instance?.edition?.number}`
                : null}
            </div>
          );
        },
      },
      {
        label: "Type",
        type: "label",
        name: "instance.edition.artwork.artworkCategory.name",
      },
      {
        label: "Year",
        type: "label",
        name: "instance.edition.year",
      },
      {
        label: "Artist(s)",
        type: "custom",
        component: props => {
          const { values } = props;
          return (
            <div>
              {values?.instance?.edition?.artwork?.artists
                ?.map(artist => artist?.name)
                .join(", ")}
            </div>
          );
        },
      },
      {
        label: "Notes",
        type: "input",
        inputType: "text",
        name: "notes",
        disabled,
      },
      {
        label: "Position",
        type: "input",
        inputType: "multi-select",
        options: [
          { value: "wall", label: "Wall" },
          { value: "floor", label: "Floor" },
        ],
        name: "artworkCat",
      },
      {
        label: "Showing",
        type: "input",
        inputType: "multi-select",
        options: [
          { value: 1, label: "Not Shown" },
          { value: 2, label: "Shown" },
          { value: 3, label: "1st Day" },
          { value: 4, label: "2nd Day" },
          { value: 5, label: "3rd Day" },
          { value: 6, label: "4th Day" },
          { value: 7, label: "5th Day" },
          { value: 8, label: "Viewing Room" },
          { value: 9, label: "Back Room" },
        ],
        name: "exhibited",
      },
      {
        label: "Technical Notes",
        type: "input",
        inputType: "text",
        name: "technicalNotes",
        disabled,
      },
      {
        label: "User",
        type: "label",
        name: "user",
      },
      {
        label: "Timestamp",
        type: "custom",
        component: props => {
          const { values } = props;
          const date = new Date(values?.updatedAt);
          return (
            <div>
              {`${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`}
            </div>
          );
        },
      },
      {
        label: "",
        name: "action",
        type: "custom",
        component: Actions,
      },
    ],
    FETCH_QUERY: FETCH_EXHIBITION_WORKS,
    UPDATE_MUTATION: UPDATE_EXHIBITION_WORK,
    REFETCH_QUERIES: ["FetchExhibitionWorks", "FetchExhibition"],
    variables: {
      exhibitionId: exhibition?.id,
    },
  };

  const actions = [
    {
      component: () => (
        <AddWork
          exhibition={exhibition}
          disabled={!hasPermission("UPDATE_EXHIBITION_WORK")}
        />
      ),
    },
  ];

  return (
    <div className="mt-10">
      <SectionHeader title="Artworks" actions={actions} />
      <div className="mb-6 w-full">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

const ExhibitionDetails = props => {
  const { exhibition, formik } = props;
  const { hasPermission } = useUser();

  return (
    <div className="mb-20 w-full">
      <h1 className="text-xl font-bold">
        {exhibition?.id}: {exhibition?.title}
      </h1>
      <div>
        <Infos
          formik={formik}
          submitOnBlur={true}
          disabled={!hasPermission("UPDATE_EXHIBITION")}
        />
      </div>
      <ExhibitionWorks
        exhibition={exhibition}
        disabled={!hasPermission("UPDATE_EXHIBITION_WORK")}
      />
    </div>
  );
};

export default ExhibitionDetails;
