import { useFormik } from "formik";
import { convertToFilter, makeObservable } from "../../../util";
import { useEffect } from "react";
import * as Yup from "yup";

const exhibitionFilterStore = makeObservable({});

const useExhibitionFilters = () => {
  const formik = useFormik({
    initialValues: {
      limit: 50,
      type: { operator: "in" },
    },
    validationSchema: Yup.object({
      startDay: Yup.number()
        .min(1, "Start Day must be at least 1")
        .max(31, "Start Day must be 31 at most")
        .nullable(),
      startYear: Yup.number()
        .min(1900, "Start Year must be at least 1900")
        .max(2099, "Start Year must be at least 2099")
        .nullable(),
      endDay: Yup.number()
        .min(1, "Start Day must be at least 1")
        .max(31, "Start Day must be 31 at most")
        .nullable(),
      endYear: Yup.number()
        .min(1900, "End Year must be at least 1900")
        .max(2099, "End Year must be at least 2099")
        .nullable(),
    }),
  });

  const filterKeys = [];

  const skipKeys = ["limit", "offset"];

  useEffect(() => {
    exhibitionFilterStore.set(
      convertToFilter({ values: formik.values, filterKeys, skipKeys }),
    );
  }, [formik.values]);

  return {
    formik,
  };
};

export { exhibitionFilterStore, useExhibitionFilters };
