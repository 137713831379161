import { gql } from "@apollo/client";
import { ExhibitionWork } from "../fragments";

const CREATE_EXHIBITION_WORK = gql`
  mutation CreateExhibitionWork($input: CreateExhibitionWorkInput!) {
    createExhibitionWork(input: $input) {
      success
      error
      exhibitionWork {
        ${ExhibitionWork}
      }
    }
  }
`;

const UPDATE_EXHIBITION_WORK = gql`
  mutation UpdateExhibitionWork($input: UpdateExhibitionWorkInput!) {
    updateExhibitionWork(input: $input) {
      exhibitionWork {
        ${ExhibitionWork}
      }
      error
      success
    }
  }
`;

const DELETE_EXHIBITION_WORK = gql`
  mutation DeleteExhibitionWork($input: DeleteExhibitionWorkInput!) {
    deleteExhibitionWork(input: $input) {
      success
      error
    }
  }
`;

export {
  CREATE_EXHIBITION_WORK,
  UPDATE_EXHIBITION_WORK,
  DELETE_EXHIBITION_WORK,
};
