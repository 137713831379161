import { gql } from "@apollo/client";
import { Shipment as shipment } from "../fragments";

const FETCH_SHIPMENTS = props => gql`
  query FetchShipments($input: ShipmentFilterInput!) {
    shipments(input: $input) {
      count
      edges {
        ${shipment(props)}
      }
    }
  }
`;

const FETCH_SHIPMENTS_V2 = props => gql`
  query FetchShipmentsV2($input: AdvShipmentFilterInput!) {
    shipmentsV2(input: $input) {
      count
      edges {
        ${shipment(props)}
      }
      sqlQuery
    }
  }
`;

const FETCH_SHIPMENT = props => gql`
  query FetchShipment($id: Int) {
    shipment(id: $id) {
      ${shipment(props)}
    }
  }
`;

const FETCH_SHIPMENTS_LIST = gql`
  query FetchShipmentsV2($input: AdvShipmentFilterInput!) {
    shipmentsV2(input: $input) {
      count
      edges {
        id
        currency {
          code
        }
        date
        delivered
        delCompany
        delAddress
        itemCount
        oriCompany
        oriName
        totalValue
      }
    }
  }
`;

export {
  FETCH_SHIPMENT,
  FETCH_SHIPMENTS,
  FETCH_SHIPMENTS_V2,
  FETCH_SHIPMENTS_LIST,
};
