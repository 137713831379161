import { useNavigate } from "react-router-dom";
import {
  SelectList,
  Table,
  multiSelect,
} from "../../../../components/Functional";
import Actions from "./components/Actions";
import HeaderComponent from "./components/HeaderComponent";
import searchedConsignments from "./components/SearchedConsignments";

const CustomCompanyCell = props => {
  return (
    <div className="max-w-[320px] overflow-hidden text-ellipsis text-base text-black">
      {props.values?.contact?.name}
      <div className="text-[15px] italic tracking-wide text-slate-500">
        {props?.values?.contact?.company !== "None" &&
          props.values.contact?.company}
      </div>
    </div>
  );
};

const ReferencesCell = props => {
  const { values } = props;
  const { consignmentWorks } = values;

  const instancesReference = consignmentWorks
    ?.map(consignmentWork => consignmentWork?.instance?.name)
    .filter(Boolean)
    .join(", ");

  return (
    <div
      title={instancesReference}
      className="max-w-[240px] overflow-hidden text-ellipsis text-base text-black"
    >
      {instancesReference}
    </div>
  );
};

const List = props => {
  const { formik } = props;
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const navigate = useNavigate();

  const GeneralTabActions = () => {
    const createListProps = {
      // eslint-disable-next-line
      ...actionProps,
      onSuccess: () => {
        formik?.resetForm();
        navigate(`/consignments?status=selectListWorking`);
      },
      typeId: 4,
    };

    return <SelectList.CreateList {...createListProps} />;
  };

  const tabProps = {
    active: status,
    onChange: () => {},
    tabs: [
      {
        actions: GeneralTabActions,
        label: "All Consignments",
        name: "all",
        tableProps: searchedConsignments(),
      },
    ],
  };

  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  const multiSelectProps = {
    formik,
    label: "Consignments",
    name: "selected",
    keyName: "id",
  };

  const tableProps = {
    variant: "dynamicHeight",
    dataKey: "consignments",
    headers: [
      multiSelect(multiSelectProps),
      { label: "Consignment Number", name: "id", type: "label" },
      { label: "Date", name: "date", type: "date" },
      { label: "Type", name: "type.description", type: "label" },
      {
        label: "Client Name & Company",
        sortKey: "company",
        type: "custom",
        component: CustomCompanyCell,
      },
      {
        label: "References",
        name: "consignmentWorks",
        type: "custom",
        component: ReferencesCell,
      },
      { label: "Description", name: "notes", type: "label" },
      { label: "# of works", name: "noOfConsignmentWorks", type: "label" },
      { label: "", name: "action", type: "custom", component: Actions },
    ],
    // inputs: [
    //   { label: "Consigner", name: "consignerId", type: "select"},
    //   { label: "Channel", name: "channelId", type: "select" },
    //   { label: "Company", name: "companyId", type: "select" },
    //   { label: "Date From", name: "dateFrom", type: "date" },
    //   { label: "Date To", name: "dateTo", type: "date" },
    //   { label: "Due Date From", name: "dueFrom", type: "date" },
    //   { label: "Due Date To", name: "dueTo", type: "date" },
    //   { label: "Type", name: "typeId", type: "select" },
    // ],
    limit: 50,
    tabProps,
    ...tabTableProps,
  };

  return (
    <>
      <div className={"px-8"}>
        <HeaderComponent
          leftText="All Consignments"
          rightComponent={() => {
            return (
              // eslint-disable-next-line
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

export default List;
