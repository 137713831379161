import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Button, getInput } from "../../../components/ui";
import { FETCH_JOB_TYPES } from "../../../graphql/query/JobType";
import { CREATE_JOB_REQUEST as createJobMutation } from "../../../graphql/mutation/Job";
import { FETCH_ENTITIES } from "../../../graphql/query/Entity";
import { FETCH_JOBS_LIST as fetchJobsList } from "../../../graphql/query/Job";

const NewJob = props => {
  const { closeModal, exhibitionId = null } = props || {};
  const navigate = useNavigate();
  const { data: { jobTypes = [] } = {} } = useQuery(FETCH_JOB_TYPES);
  const [createJob, { loading }] = useMutation(createJobMutation(), {
    refetchQueries: [fetchJobsList],
  });
  const { data: { entities: pickupGroups } = {} } = useQuery(FETCH_ENTITIES, {
    variables: { input: { hide: 0, user: 0, order: ["name"] } },
  });

  const formik = useFormik({
    initialValues: { statusId: 1, date: new Date() },
    validationSchema: Yup.object({
      typeId: Yup.string().trim().required("You must select type for new job."),
      description: Yup.string()
        .trim()
        .required("You must enter description for new job."),
      dueDate: Yup.date().required("You must select due date for new job."),
      duetime: Yup.string()
        .required("Due-Time must be greater than or equal to 10 AM GMT")
        .test(
          "validation",
          `Due-Time must be greater than or equal to 10 AM GMT`,
          function (value) {
            const splitTime = value.split(":");
            return Number(splitTime[0]) >= 10;
          },
        ),
      pickupId: Yup.number()
        .typeError("You must enter pickup for new job.")
        .required("You must enter pickup for new job."),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const error = {};
      const updatedDueTime = new Date(`${values.dueDate} ${values.duetime}`);

      if (!(values?.duetime.split(":")[0] >= 10)) {
        error.duetime = `Due-Time must be greater than or equal to 10 AM GMT`;
      }

      if (Object.keys(error).length <= 0) {
        createJob({
          variables: {
            input: {
              ...values,
              duetime: updatedDueTime,
              exhibitionId: +exhibitionId,
            },
          },
        }).then(resp => {
          const {
            data: { createJob: { success, job } = {} },
          } = resp || {};
          if (success) {
            closeModal();
            resetForm();
            navigate(`/jobs/${job?.id}/details`);
          }
        });
      } else {
        setErrors(error);
      }
    },
  });

  const inputs = [
    {
      label: "Type",
      name: "typeId",
      type: "select",
      placeholder: "Select a type",
      options: jobTypes?.map(item => ({
        label: item.description,
        value: item.id,
      })),
    },
    { label: "Description", name: "description", type: "text" },
    { label: "Due Date", name: "dueDate", type: "date" },
    {
      label: "Due Time",
      name: "duetime",
      type: "time",
      disabled: !formik.getFieldProps("dueDate").value,
    },
    {
      label: "Pickup Group",
      name: "pickupId",
      type: "multi-select",
      options:
        pickupGroups?.edges?.map(item => ({
          label: item?.name,
          value: item?.id,
        })) || [],
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a New Job</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default NewJob;
