import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { GridInputs } from "../../../../components/Functional";
import { Button, Spinner } from "../../../../components/ui";

import { jobFilterStore, useJobFilters } from "../useJobsFilters";

import Query from "./components/Query";
import Tabs from "./components/Tabs";
import { FETCH_JOBS_LIST as fetchJobsListQuery } from "../../../../graphql/query/Job";
import { FETCH_JOB_TYPES } from "../../../../graphql/query/JobType";
import { FETCH_JOB_STATUSES } from "../../../../graphql/query/JobStatus";
import Sort from "./components/Sort";
import Instance from "./components/Instance";
import { FETCH_ENTITIES } from "../../../../graphql/query/Entity";
import { useEffect } from "react";

const JobSearch = props => {
  const { closeModal, isSearchOpen = false } = props || {};
  const [fetchJobsV2, { loading, data: { jobsV2 } = {} }] =
    useLazyQuery(fetchJobsListQuery);

  const [
    fetchJobTypesLazily,
    { loading: jobTypesLoading, data: { jobTypes = [] } = {} },
  ] = useLazyQuery(FETCH_JOB_TYPES);
  const [
    fetchJobStatusesLazily,
    { loading: jobStatusLoading, data: { jobStatuses = [] } = {} },
  ] = useLazyQuery(FETCH_JOB_STATUSES);
  const [
    fetchEntitiesLazily,
    { loading: entitiesLoading, data: { entities } = {} },
  ] = useLazyQuery(FETCH_ENTITIES);

  const [
    fetchFilteredEntitiesLazily,
    {
      loading: filteredEntitiesLoading,
      data: { entities: filteredEntities } = {},
    },
  ] = useLazyQuery(FETCH_ENTITIES, {
    variables: { input: { user: 0, hide: 0 } },
  });

  useEffect(() => {
    if (isSearchOpen) {
      fetchJobTypesLazily();
      fetchJobStatusesLazily();
      fetchEntitiesLazily();
      fetchFilteredEntitiesLazily();
    }
  }, [isSearchOpen]);

  const navigate = useNavigate();
  const { formik } = useJobFilters();
  const input = jobFilterStore.get();

  const inputs = {
    className: "grid grid-cols-4 gap-16 mt-5",
    inputs: [
      {
        className: "gap-4",
        inputs: [
          {
            label: "Number",
            name: "id",
            type: "number",
          },
          {
            label: "Type",
            name: "typeId",
            type: "multi-select",
            options: jobTypes?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
          },
          {
            label: "Status",
            name: "statusId",
            options: jobStatuses?.map(item => ({
              label: item?.description,
              value: item?.id,
            })),
            type: "multi-select",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            label: "Confirmed By",
            isMulti: true,
            name: "userId",
            type: "multi-select",
            options:
              entities?.edges?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
          },
          {
            label: "Requested By",
            name: "requestUserId",
            isMulti: true,
            type: "multi-select",
            options:
              entities?.edges?.map(item => ({
                label: item?.name,
                value: item?.id,
              })) || [],
          },
          { label: "Description", name: "description", type: "text" },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          { label: "Due From", name: "dueDate.value.from", type: "date" },
          { label: "Due To", name: "dueDate.value.to", type: "date" },
          {
            hasOperator: true,
            isMulti: true,
            label: "Pickup",
            name: "pickupId",
            type: "multi-select",
            options: filteredEntities?.edges?.map(item => ({
              label: item?.name,
              value: item?.id,
            })),
          },
          {
            label: "Consigment",
            name: "consignmentId",
            type: "consignmentSearch",
          },
        ],
      },
      {
        className: "grid-cols-1 gap-4",
        inputs: [
          {
            designType: "threeStateCheckbox",
            label: "Charge Out",
            name: "chargeOut",
            type: "toggle",
          },
        ],
      },
    ],
  };

  const inputProps = {
    formik,
    ...inputs,
  };

  const searchButtonProps = {
    action: "black",
    label: loading ? "Searching" : "Search",
    disabled: loading,
    onClick: e =>
      fetchJobsV2({
        variables: {
          input,
        },
      }).then(resp => {
        navigate("/jobs?status=all");
        closeModal();
      }),
  };

  const resetButtonProps = {
    action: "default",
    label: "Reset",
    onClick: formik?.resetForm,
  };

  const tabComponentProps = {
    jobsV2,
    formik,
  };

  const tabsProps = {
    formik,
    name: "tab",
    options: [
      {
        label: "Instance",
        value: "instance",
        component: <Instance {...tabComponentProps} />,
      },
      {
        label: "Sort",
        value: "sort",
        component: <Sort {...tabComponentProps} />,
      },
      {
        label: "Query",
        value: "query",
        component: <Query {...tabComponentProps} />,
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div className="text-xl font-bold">Job Search</div>
        <div className="flex gap-4">
          <Button {...resetButtonProps} />
          <Button {...searchButtonProps} />
        </div>
      </div>
      {entitiesLoading ||
      jobTypesLoading ||
      jobStatusLoading ||
      filteredEntitiesLoading ? (
        <div className="flex h-[70dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <GridInputs {...inputProps} />
          <Tabs {...tabsProps} />
        </div>
      )}
    </div>
  );
};

export default JobSearch;
