import { FETCH_CONSIGNMENTS_LIST as fetchConsignmentsList } from "../../../../../graphql/query/Consignment";
import { consignmentFilterStore } from "../../../components/useConsignmentFilters";

const SearchedConsignments = props => {
  const tableProps = {
    dataKey: "consignmentsV2",
    FETCH_QUERY: fetchConsignmentsList,
    limit: 50,
    variables: {
      ...consignmentFilterStore.get(),
    },
  };

  return tableProps;
};

export default SearchedConsignments;
