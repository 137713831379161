import { useMutation } from "@apollo/client";
import { Button, getInput, Modal } from "../../../../../../../components/ui";
import { CREATE_EXHIBITION_WORK } from "../../../../../../../graphql/mutation/ExhibitionWork";
import { useFormik } from "formik";

const AddWorkModal = props => {
  const { closeModal, exhibition } = props;
  const [createExhibitionWork, { loading, error, reset }] = useMutation(
    CREATE_EXHIBITION_WORK,
    {
      refetchQueries: [
        "FetchExhibitionWorks",
        "FetchExhibition",
        "fetchConsignmentWorks",
      ],
    },
  );

  const handleClick = () => {
    createExhibitionWork({
      variables: {
        input: {
          exhibitionId: exhibition?.id,
          ...formik?.values,
        },
      },
    }).then(resp => {
      const { data: { createExhibitionWork: { success } = {} } = {} } = resp;

      if (success) {
        resetAll();
      }
    });
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleClick,
  });

  const resetAll = () => {
    formik?.resetForm();
    reset?.();
    closeModal?.();
  };

  const inputs = [
    {
      label: "Instance",
      name: "instanceId",
      type: "instanceSearch",
    },
    {
      label: "Notes",
      name: "notes",
      type: "textarea",
      rows: 2,
    },
    {
      label: "Technical Notes",
      name: "technicalNotes",
      type: "textarea",
      rows: 2,
    },
  ];

  if (error) {
    return (
      <div className="flex flex-col w-full mt-6">
        <div className="flex flex-row">
          <div className="flex flex-col flex-1">
            <div className="text-2xl font-bold">Error adding work</div>
          </div>
          <div className="flex flex-col">
            <div>
              <Button
                action="default"
                className="mr-4"
                label="Cancel"
                onClick={() => {
                  resetAll();
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="py-6">{error?.message}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-row">
        <div className="flex flex-col flex-1 text-2xl font-bold">
          <div>Add Exhibition Artwork</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label="Create"
            loading={loading}
            disabled={loading}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 w-50">
        {inputs
          ?.filter(item => !!item)
          .map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
      </div>
    </div>
  );
};

const AddWork = props => {
  const { exhibition, disabled } = props || {};

  const modalProps = {
    body: AddWorkModal,
    closeOnBackdrop: false,
    exhibition,
    hideCloseButton: true,
    scale: "md",
    disabled,
  };

  return (
    <Modal {...modalProps}>
      <Button label="Add Work" action="black" disabled={disabled} />
    </Modal>
  );
};

export default AddWork;
