import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, getInput } from "../../../../components/ui";
import { FETCH_CONSIGNMENTS_TYPES } from "../../../../graphql/query/ConsignmentType";
import { CREATE_CONSIGNMENT as createConsignmentMutation } from "../../../../graphql/mutation/Consignment";
import { CREATE_CONSIGNMENT_WORK as createConsignmentWorkMutation } from "../../../../graphql/mutation/ConsignmentWork";
import { FETCH_CURRENCIES } from "../../../../graphql/query/Currency";

const NewConsignment = props => {
  const { closeModal, exhibition, typeId = 1 } = props || {};
  const navigate = useNavigate();
  const [createConsignment, { loading }] = useMutation(
    createConsignmentMutation(),
  );

  const [createConsignmentWork, { workLoading }] = useMutation(
    createConsignmentWorkMutation(),
  );

  const { data: { consignmentTypes = [] } = {} } = useQuery(
    FETCH_CONSIGNMENTS_TYPES,
  );
  const { data: { currencies = [] } = {} } = useQuery(FETCH_CURRENCIES);

  const formik = useFormik({
    initialValues: {
      typeId: typeId,
    },
    validationSchema: Yup.object({
      consignerId: Yup.number().required("Please select a Consigner"),
      contactId: Yup.number().required("Please select a Consignee"),
      typeId: Yup.string().required("Please select a type"),
      date: Yup.date().required("Please enter a date"),
      due: Yup.date()
        .min(Yup.ref("date"), "Due date can't be before date")
        .when("typeId", {
          is: val => {
            val = parseInt(val);
            return val === 2;
          },
          then: schema => schema.required("Please enter a due date"),
        }),
      currencyId: Yup.number().required("Please select a currency"),
      notes: Yup.string(),
    }),
    onSubmit: async values => {
      createConsignment({
        variables: {
          input: {
            exhibitionId: exhibition.id,
            ...values,
          },
        },
      }).then(resp => {
        const {
          data: { createConsignment: { success, consignment } = {} },
        } = resp || {};
        let worksAdded = 0;
        exhibition?.exhibitionWorks.forEach(work => {
          createConsignmentWork({
            variables: {
              input: {
                consignmentId: consignment.id,
                instanceId: work.id,
              },
            },
          });
          worksAdded++;
          if (worksAdded === exhibition?.exhibitionWorks?.length) {
            if (success) {
              navigate(`/consignment/${consignment?.id}/form`);
              formik.resetForm();
              closeModal();
            }
          }
        });
      });
    },
  });

  const inputs = [
    {
      label: "Consigner",
      name: "consignerId",
      type: "contactSearch",
    },
    {
      label: "Consignee",
      name: "contactId",
      type: "contactSearch",
    },
    { label: "Date", name: "date", type: "date" },
    {
      label: "Type",
      name: "typeId",
      type: "multi-select",
      options:
        consignmentTypes?.map(item => {
          return { label: item.description, value: item.id };
        }) || [],
    },
    { label: "Due Date", name: "due", type: "date" },
    { label: "Notes", name: "notes", type: "textarea" },
    {
      label: "Currency",
      name: "currencyId",
      type: "multi-select",
      options:
        currencies?.map(item => {
          return { label: item.description, value: item.id };
        }) || [],
    },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4 p-6">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Add a new consignment</div>
        </div>
        <div className="flex gap-4">
          <Button action="default" label="Cancel" onClick={closeModal} />
          <Button
            label={"Create"}
            loading={loading || workLoading}
            disabled={loading || workLoading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      <div className="w-50 grid grid-cols-2 gap-4">
        {inputs?.map((item, index) => {
          const inputProps = {
            ...item,
            formik,
          };
          return <div key={index}>{getInput(inputProps)}</div>;
        })}
      </div>
    </div>
  );
};

export default NewConsignment;
