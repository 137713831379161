import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { FETCH_LISTS as fetchLists } from "../../../../graphql/query/List";
import { useEffect } from "react";
import { exhibitionFilterStore } from "../../components/useExhibitionFilters";
import {
  Table,
  multiSelect,
  SelectList,
} from "../../../../components/Functional";
import searchedExhibitions from "./components/SearchedExhibitions";
import * as SelectListWorking from "./components/SelectListWorking";
import * as SelectListSaved from "./components/SelectListSaved";
import Header from "./components/Header";
import Actions from "./components/Actions";
import * as ImageSelectListWorking from "./components/ImageSelectListWorking";
import * as ImageSelectListSaved from "./components/ImageSelectListSaved";

const List = props => {
  const { formik } = props;
  const navigate = useNavigate();

  const { data: { lists: { edges = [] } = {} } = {} } = useQuery(fetchLists(), {
    variables: {
      input: { typeId: 24 },
    },
  });

  const { data: { lists: { edges: imageListEdges = [] } = {} } = {} } =
    useQuery(fetchLists(), {
      variables: {
        // fetch exhibition_image_working list
        input: { typeId: 27 },
      },
    });
  const workingList = edges?.length > 0 ? edges[0] : null;
  const imageWorkingListId =
    imageListEdges?.length > 0 ? imageListEdges[0]?.id : null;
  const workingListId = workingList?.id;
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams?.get("status") || "all";
  const listId = +queryParams.get("listId");
  const imageListId = +queryParams.get("imageListId");

  const ids = Object.keys(formik?.values?.selected?.ids || {})?.map(item =>
    parseInt(item.replace("_", "")),
  );

  const select = formik?.values?.selected?.select;

  useEffect(() => {
    if (workingListId) {
      queryParams.set("workingListId", workingListId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      // eslint-disable-next-line
      history.pushState(null, null, newUrl);
    }
  }, [workingListId]);

  const multiSelectProps = {
    formik,
    label: "Exhibitions",
    name: "selected",
    keyName: "id",
  };

  const actionProps = {
    formik,
    ids,
    imageListId,
    imageWorkingListId,
    listId,
    onSuccess: () => formik?.resetForm(),
    search: exhibitionFilterStore.get(),
    workingListId,
  };

  const handleChange = value => {
    formik?.resetForm();
    navigate(
      `/exhibition?status=${value}${listId ? `&listId=${listId}` : ""}${workingListId ? `&workingListId=${workingListId}` : ""}`,
    );
  };

  const GeneralTabActions = () => {
    const createListProps = {
      ...actionProps,
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/exhibition?status=selectListWorking${listId ? `&listId=${listId}` : ""}`,
        )
      ),
      typeId: 24,
    };

    const createImageListProps = {
      ...actionProps,
      label: "Add Images to Select List",
      onSuccess: () => (
        formik?.resetForm(),
        navigate(
          `/exhibition?status=imageSelectListWorking${listId ? `&listId=${listId}` : ""}${imageListId ? `&imageListId=${imageListId}` : ""}`,
        )
      ),
      typeId: 27,
      isSearch: true,
      ...(select !== "all"
        ? { search: { exhibition: { operator: "in", value: ids } } }
        : {}),
    };

    return (
      <>
        <SelectList.CreateList {...createImageListProps} />
        <SelectList.CreateList {...createListProps} />
      </>
    );
  };

  const tabProps = {
    active: status,
    onChange: handleChange,
    tabs: [
      {
        actions: GeneralTabActions,
        label: "All Exhibitions",
        name: "all",
        tableProps: searchedExhibitions(),
      },
      {
        actions: SelectListWorking.Actions,
        label: "Select List (Working)",
        name: "selectListWorking",
        tableProps: SelectListWorking.tableProps(actionProps),
      },
      {
        actions: SelectListSaved.Actions,
        label: "Select List (Saved)",
        name: "selectListSaved",
        tableProps: SelectListSaved.tableProps(actionProps),
      },
      {
        actions: ImageSelectListWorking.Actions,
        label: "Image Select List (Working)",
        name: "imageSelectListWorking",
        tableProps: ImageSelectListWorking.tableProps({
          imageListId,
          imageWorkingListId,
          formik,
        }),
      },
      {
        actions: ImageSelectListSaved.Actions,
        label: "Image Select List (Saved)",
        name: "imageSelectListSaved",
        tableProps: ImageSelectListSaved.tableProps({ formik }),
      },
    ],
  };

  const { tableProps: tabTableProps, actions: tabActions } =
    tabProps?.tabs?.find(tab => tab.name === status) || {};

  const fixHeadersName = ({ status, headers }) =>
    headers?.map(header => {
      return {
        ...header,
        name:
          header?.name && status === "selectListWorking"
            ? Array.isArray(header?.name)
              ? header?.name?.map(item => `item.${item}`)
              : `item.${header?.name}`
            : header?.name,
      };
    });

  const tableProps = {
    headers: fixHeadersName({
      status,
      headers: [
        multiSelect(multiSelectProps),
        {
          label: "ID",
          name: "id",
          sortKey: "id",
          type: "label",
        },
        {
          label: "Title",
          name: "title",
          type: "label",
        },
        {
          label: "Venue",
          name: "contactName",
          type: "custom",
          status,
          component: CustomCompanyCell,
        },
        {
          label: "Start Date",
          name: "startDate",
          type: "custom",
          startDate: true,
          status,
          component: DateTableField,
        },
        {
          label: "End Date",
          name: "endDate",
          type: "custom",
          startDate: false,
          status,
          component: DateTableField,
        },
        { label: "", type: "custom", component: Actions },
      ],
    }),
    limit: 50,
    tabProps,
    variant: "dynamicHeight",
    ...tabTableProps,
  };

  return (
    <>
      <div className="px-8">
        <Header
          leftText="All Exhibitions"
          rightComponent={() => {
            return (
              <div className="flex gap-4">{tabActions?.(actionProps)}</div>
            );
          }}
        />
      </div>
      <div className="mt-6 w-full">
        <Table {...tableProps} />
      </div>
    </>
  );
};

const CustomCompanyCell = props => {
  const { values, status } = props;
  const item = status === "selectListWorking" ? values?.item : values;
  return (
    <div className="max-w-[320px] overflow-hidden text-ellipsis text-base text-black">
      {item?.contact?.name}
      <div className="text-[15px] italic tracking-wide text-slate-500">
        {item?.contact?.company !== "None" && item.contact?.company}
      </div>
    </div>
  );
};

const DateTableField = props => {
  const { values, startDate, status } = props;
  const item = status === "selectListWorking" ? values?.item : values;
  const dateDay = startDate ? item?.startDay : item?.endDay;
  const dateMonth = startDate ? item?.startMonth : item?.endMonth;
  const dateYear = startDate ? item?.startYear : item?.endYear;

  if (dateDay === null && dateMonth === null && dateYear === null) {
    return null;
  }

  return (
    <div>
      <span>{dateDay ? (`0` + dateDay).slice(-2) : "??"}</span>/
      <span>{dateMonth ? (`0` + dateMonth).slice(-2) : "??"}</span>/
      <span>{dateYear || "????"}</span>
    </div>
  );
};

export default List;
