import { Route, Routes as RouterRoutes, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import routes from "./routes";
import { UPDATE_EXHIBITION } from "../../../../graphql/mutation/Exhibition";
import { useEffect, useRef } from "react";
import { useFormik } from "formik";
import { getChangedFields, Portal } from "../../../../util";
import { NavBar } from "../../../../components/ui";
import AuthRoute from "../../../../components/AuthRoute";
import { FETCH_EXHIBITION } from "../../../../graphql/query/Exhibition";
import * as Yup from "yup";

const Body = props => {
  return (
    <RouterRoutes>
      {routes
        ?.filter?.(item => item?.element)
        ?.map((item, index) => {
          return (
            <Route
              key={index}
              path={item?.href}
              element={
                <AuthRoute permissions={item.permissions}>
                  <item.element {...props} />
                </AuthRoute>
              }
            />
          );
        })}
    </RouterRoutes>
  );
};

const Wizard = props => {
  const { id } = useParams();
  const { data: { exhibition } = {} } = useQuery(
    FETCH_EXHIBITION({ contact: {} }),
    {
      variables: { id: +id },
    },
  );

  const [updateExhibition, { loading }] = useMutation(
    UPDATE_EXHIBITION({ contact: {} }),
    {
      refetchQueries: ["FetchExhibition"],
    },
  );

  const abortController = useRef();

  const formik = useFormik({
    initialValues: exhibition ? exhibition : [],
    validationSchema: Yup.object({
      contactId: Yup.number().required("Please select a contact"),
      title: Yup.string().required("Please enter an exhibition title"),
      startDay: Yup.number()
        .nullable()
        .min(1, "Start Day must be at least 1")
        .max(31, "Start Day must be 31 at most"),
      startYear: Yup.number()
        .nullable()
        .min(1900, "Start Year must be at least 1900")
        .max(2099, "Start Year must be at least 2099"),
      endDay: Yup.number()
        .nullable()
        .min(1, "End Day must be at least 1")
        .max(31, "End Day must be 31 at most"),
      endYear: Yup.number()
        .nullable()
        .min(1900, "End Year must be at least 1900")
        .max(2099, "End Year must be at least 2099"),
      sageCode: Yup.string().required("Please enter a Sage Code"),
    }),
    enableReinitialize: true,
    onSubmit: values => {
      updateExhibitionValues(values);
    },
  });

  const updateExhibitionValues = values => {
    const changedFields = getChangedFields(values, exhibition);

    if (Object.keys(changedFields).length > 0) {
      const controller = new AbortController();
      abortController.current = controller;

      updateExhibition({
        variables: {
          input: {
            id: +id,
            ...changedFields,
          },
        },
        context: {
          fetchOptions: {
            signal: controller.signal,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (formik?.values && abortController?.current) {
      abortController.current.abort();
    }
  }, [formik?.values]);

  useEffect(() => {
    formik.resetForm({
      values: {
        ...exhibition,
      },
    });
  }, [exhibition]);

  const bodyProps = {
    ...props,
    exhibition,
    exhibitionId: +id,
    formik,
    loading,
    updateExhibition,
    updateExhibitionValues,
  };

  return (
    <div className="relative flex min-h-full">
      <Portal id="sidebar">
        <NavBar
          navigation={routes?.filter?.(item => item?.icon)}
          open={props?.open}
        />
      </Portal>
      <div className="m-8 mr-[80px] flex flex-1 overflow-x-auto">
        {exhibition && <Body {...bodyProps} />}
      </div>
    </div>
  );
};

export default Wizard;
