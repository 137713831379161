import { useMutation, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, getInput, Spinner } from "../../../components/ui";
import { FETCH_ARTISTS as fetchArtists } from "../../../graphql/query/Artist";
import { FETCH_ARTWORK_CATEGORIES } from "../../../graphql/query/ArtworkCategory";
import {
  FETCH_ARTWORKS as fetchArtworks,
  FETCH_ARTWORKS_LIST as fetchArtworksList,
} from "../../../graphql/query/Artwork";
import { CREATE_ARTWORK as createArtwork } from "../../../graphql/mutation/Artwork";
import { useEffect } from "react";

const NewArtwork = props => {
  const { closeModal, isCreateOpen = false } = props || {};
  const navigate = useNavigate();
  const [createArtWork, { loading }] = useMutation(createArtwork({}), {
    refetchQueries: [fetchArtworks(), fetchArtworksList()],
  });

  const [
    fetchArtistsLazily,
    { loading: artistsLoading, data: { artists = [] } = {} },
  ] = useLazyQuery(fetchArtists());

  const [
    fetchArtworkCategoriessLazily,
    {
      loading: artworkCategoriesLoading,
      data: { artworkCategories = [] } = {},
    },
  ] = useLazyQuery(FETCH_ARTWORK_CATEGORIES);

  useEffect(() => {
    if (isCreateOpen) {
      fetchArtistsLazily();
      fetchArtworkCategoriessLazily();
    }
  }, [isCreateOpen]);

  const formik = useFormik({
    initialValues: { title: "", artistIds: [], categoryId: "" },
    validationSchema: Yup.object({
      artistIds: Yup.array().min(1, "Please select an artist"),
      categoryId: Yup.number().required("Please select a category").nullable(),
      title: Yup.string().trim().required("Please enter a title"),
    }),
    onSubmit: async values => {
      createArtWork({
        variables: {
          input: {
            ...values,
            artistIds: values?.artistIds?.map(item => parseInt(item.value)),
          },
        },
      }).then(resp => {
        const {
          data: { createArtwork: { success, artwork } = {} },
        } = resp || {};
        if (success) {
          formik.resetForm();
          navigate(`/artworks/${artwork?.id}/details`);
          closeModal();
        }
      });
    },
  });

  const inputs = [
    {
      isMulti: true,
      label: "Artist",
      name: "artistIds",
      type: "multi-select",
      options: artists?.map(item => ({ label: item.name, value: item.id })),
    },
    {
      label: "Category",
      name: "categoryId",
      type: "multi-select",
      options: artworkCategories?.map(item => ({
        label: item.name,
        value: item.id,
      })),
    },
    { label: "Title", name: "title", type: "textarea" },
  ];

  const hasErrors = Object.keys(formik?.errors || {}).length > 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex flex-1 flex-col text-2xl font-bold">
          <div>Create a New Artwork</div>
        </div>
        <div className="flex gap-4">
          <Button
            action="default"
            label="Cancel"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
          />
          <Button
            label={"Create"}
            loading={loading}
            disabled={loading || hasErrors}
            onClick={formik.submitForm}
          />
        </div>
      </div>
      {artistsLoading || artworkCategoriesLoading ? (
        <div className="flex h-[30dvh] w-full items-center justify-center ">
          <Spinner />
        </div>
      ) : (
        <div className="w-50 grid grid-cols-2 gap-4">
          {inputs?.map((item, index) => {
            const inputProps = {
              ...item,
              formik,
            };
            return <div key={index}>{getInput(inputProps)}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export default NewArtwork;
